import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedAllIndicatorBadgeTopPreview(props) {
  const {
    AvatarRoundedLgIndicatorBadgeTopText,
    AvatarRoundedLgIndicatorBadgeTopImg,
    AvatarRoundedLgIndicatorBadgeTopIcon,
    AvatarRoundedBaseIndicatorBadgeTopText,
    AvatarRoundedBaseIndicatorBadgeTopImg,
    AvatarRoundedBaseIndicatorBadgeTopIcon,
    AvatarRoundedSmIndicatorBadgeTopText,
    AvatarRoundedSmIndicatorBadgeTopImg,
    AvatarRoundedSmIndicatorBadgeTopIcon,
    AvatarRoundedXsIndicatorBadgeTopText,
    AvatarRoundedXsIndicatorBadgeTopImg,
    AvatarRoundedXsIndicatorBadgeTopIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded top indicator badge img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgIndicatorBadgeTopImg}
          componentName="AvatarRoundedLgIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded lg sized img avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=26"
              alt="user name"
              title="user name"
              width=" 48"
              height=" 48"
              className="max-w-full rounded"
            />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized img avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseIndicatorBadgeTopImg}
          componentName="AvatarRoundedBaseIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded base sized img avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=27"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded "
            />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized img avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmIndicatorBadgeTopImg}
          componentName="AvatarRoundedSmIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded sm sized img avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=28"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded "
            />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized img avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsIndicatorBadgeTopImg}
          componentName="AvatarRoundedXsIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded xs sized img avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=29"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded "
            />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized img avatars with top right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded top indicator badge img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded top indicator badge icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgIndicatorBadgeTopIcon}
          componentName="AvatarRoundedLgIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded lg sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-41 desc-41"
              role="graphics-symbol"
            >
              <title id="title-41">User Icon</title>
              <desc id="desc-41">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized icon avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseIndicatorBadgeTopIcon}
          componentName="AvatarRoundedBaseIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded base sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-42 desc-42"
              role="graphics-symbol"
            >
              <title id="title-42">User Icon</title>
              <desc id="desc-42">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized icon avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmIndicatorBadgeTopIcon}
          componentName="AvatarRoundedSmIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded sm sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-43 desc-43"
              role="graphics-symbol"
            >
              <title id="title-43">User Icon</title>
              <desc id="desc-43">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized icon avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsIndicatorBadgeTopIcon}
          componentName="AvatarRoundedXsIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded xs sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-44 desc-44"
              role="graphics-symbol"
            >
              <title id="title-44">User Icon</title>
              <desc id="desc-44">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized icon avatars with top right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded top indicator badge icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded top indicator badge text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgIndicatorBadgeTopText}
          componentName="AvatarRoundedLgIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded lg sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized text avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseIndicatorBadgeTopText}
          componentName="AvatarRoundedBaseIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded base sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized text avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmIndicatorBadgeTopText}
          componentName="AvatarRoundedSmIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded sm sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized text avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsIndicatorBadgeTopText}
          componentName="AvatarRoundedXsIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded xs sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized text avatars with top right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded top indicator badge text avatars --> */}
      </div>
    </div>
  )
}
