import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllBasicPreview(props) {
  const {
    AvatarRoundedFullLgBasicText,
    AvatarRoundedFullLgBasicImg,
    AvatarRoundedFullLgBasicIcon,
    AvatarRoundedFullBaseBasicText,
    AvatarRoundedFullBaseBasicImg,
    AvatarRoundedFullBaseBasicIcon,
    AvatarRoundedFullSmBasicText,
    AvatarRoundedFullSmBasicImg,
    AvatarRoundedFullSmBasicIcon,
    AvatarRoundedFullXsBasicText,
    AvatarRoundedFullXsBasicImg,
    AvatarRoundedFullXsBasicIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full basic img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBasicImg}
          componentName="AvatarRoundedFullLgBasicImg"
        >
          {/*<!-- Component: Rounded full lg sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=6"
              alt="user name"
              title="user name"
              width="48"
              height="48"
              className="max-w-full rounded-full"
            />
          </a>
          {/*<!-- End Rounded full lg sized basic img avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBasicImg}
          componentName="AvatarRoundedFullBaseBasicImg"
        >
          {/*<!-- Component: Rounded full base sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=7"
              alt="user name"
              title="user name"
              width="40"
              height="40"
              className="max-w-full rounded-full"
            />
          </a>
          {/*<!-- End Rounded full base sized basic img avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBasicImg}
          componentName="AvatarRoundedFullSmBasicImg"
        >
          {/*<!-- Component: Rounded full sm sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=8"
              alt="user name"
              title="user name"
              width="32"
              height="32"
              className="max-w-full rounded-full"
            />
          </a>
          {/*<!-- End Rounded full sm sized basic img avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBasicImg}
          componentName="AvatarRoundedFullXsBasicImg"
        >
          {/*<!-- Component: Rounded full xs sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=9"
              alt="user name"
              title="user name"
              width="24"
              height="24"
              className="max-w-full rounded-full"
            />
          </a>
          {/*<!-- End Rounded full xs sized basic img avatar --> */}
        </CopyComponent>
        {/*<!-- End All rounded full basic img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full basic icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBasicIcon}
          componentName="AvatarRoundedFullLgBasicIcon"
        >
          {/*<!-- Component: Rounded full lg sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-05 desc-05"
              role="graphics-symbol"
            >
              <title id="title-05">User Icon</title>
              <desc id="desc-05">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full lg sized basic icon avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBasicIcon}
          componentName="AvatarRoundedFullBaseBasicIcon"
        >
          {/*<!-- Component: Rounded full base sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-06 desc-06"
              role="graphics-symbol"
            >
              <title id="title-06">User Icon</title>
              <desc id="desc-06">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full base sized basic icon avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBasicIcon}
          componentName="AvatarRoundedFullSmBasicIcon"
        >
          {/*<!-- Component: Rounded full sm sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-07 desc-07"
              role="graphics-symbol"
            >
              <title id="title-07">User Icon</title>
              <desc id="desc-07">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full sm sized basic icon avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBasicIcon}
          componentName="AvatarRoundedFullXsBasicIcon"
        >
          {/*<!-- Component: Rounded full xs sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-08 desc-08"
              role="graphics-symbol"
            >
              <title id="title-08">User Icon</title>
              <desc id="desc-08">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full xs sized basic icon avatar --> */}
        </CopyComponent>
        {/*<!-- End All rounded full basic icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full basic text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBasicText}
          componentName="AvatarRoundedFullLgBasicText"
        >
          {/*<!-- Component: Rounded full lg sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full lg sized basic text avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBasicText}
          componentName="AvatarRoundedFullBaseBasicText"
        >
          {/*<!-- Component: Rounded full base sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full base sized basic text avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBasicText}
          componentName="AvatarRoundedFullSmBasicText"
        >
          {/*<!-- Component: Rounded full sm sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full sm sized basic text avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBasicText}
          componentName="AvatarRoundedFullXsBasicText"
        >
          {/*<!-- Component: Rounded full xs sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full xs sized basic text avatar --> */}
        </CopyComponent>
        {/*<!-- End All rounded full basic text avatars --> */}
      </div>
    </div>
  )
}
