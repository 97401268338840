import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedAllBasicPreview(props) {
  const {
    AvatarRoundedLgBasicText,
    AvatarRoundedLgBasicImg,
    AvatarRoundedLgBasicIcon,
    AvatarRoundedBaseBasicText,
    AvatarRoundedBaseBasicImg,
    AvatarRoundedBaseBasicIcon,
    AvatarRoundedSmBasicText,
    AvatarRoundedSmBasicImg,
    AvatarRoundedSmBasicIcon,
    AvatarRoundedXsBasicText,
    AvatarRoundedXsBasicImg,
    AvatarRoundedXsBasicIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded basic img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBasicImg}
          componentName="AvatarRoundedLgBasicImg"
        >
          {/*<!-- Component: Rounded lg sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=1"
              alt="user name"
              title="user name"
              width="48"
              height="48"
              className="max-w-full rounded"
            />
          </a>
          {/*<!-- End Rounded lg sized basic img avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBasicImg}
          componentName="AvatarRoundedBaseBasicImg"
        >
          {/*<!-- Component: Rounded base sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=3"
              alt="user name"
              title="user name"
              width="40"
              height="40"
              className="max-w-full rounded"
            />
          </a>
          {/*<!-- End Rounded base sized basic img avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBasicImg}
          componentName="AvatarRoundedSmBasicImg"
        >
          {/*<!-- Component: Rounded sm sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=4"
              alt="user name"
              title="user name"
              width="32"
              height="32"
              className="max-w-full rounded"
            />
          </a>
          {/*<!-- End Rounded sm sized basic img avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBasicImg}
          componentName="AvatarRoundedXsBasicImg"
        >
          {/*<!-- Component: Rounded xs sized basic img avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=5"
              alt="user name"
              title="user name"
              width="24"
              height="24"
              className="max-w-full rounded"
            />
          </a>
          {/*<!-- End Rounded xs sized basic img avatar --> */}
        </CopyComponent>
        {/*<!-- End All rounded basic img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded basic icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBasicIcon}
          componentName="AvatarRoundedLgBasicIcon"
        >
          {/*<!-- Component: Rounded lg sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-01 desc-01"
              role="graphics-symbol"
            >
              <title id="title-01">Icon title</title>
              <desc id="desc-01">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded lg sized basic icon avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBasicIcon}
          componentName="AvatarRoundedBaseBasicIcon"
        >
          {/*<!-- Component: Rounded base sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-02 desc-02"
              role="graphics-symbol"
            >
              <title id="title-02">User Icon</title>
              <desc id="desc-02">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded base sized basic icon avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBasicIcon}
          componentName="AvatarRoundedSmBasicIcon"
        >
          {/*<!-- Component: Rounded sm sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-03 desc-03"
              role="graphics-symbol"
            >
              <title id="title-03">User Icon</title>
              <desc id="desc-03">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded sm sized basic icon avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBasicIcon}
          componentName="AvatarRoundedXsBasicIcon"
        >
          {/*<!-- Component: Rounded xs sized basic icon avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-04 desc-04"
              role="graphics-symbol"
            >
              <title id="title-04">User Icon</title>
              <desc id="desc-04">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded xs sized basic icon avatar --> */}
        </CopyComponent>
        {/*<!-- End All rounded basic icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded basic text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBasicText}
          componentName="AvatarRoundedLgBasicText"
        >
          {/*<!-- Component: Rounded lg sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded lg sized basic text avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBasicText}
          componentName="AvatarRoundedBaseBasicText"
        >
          {/*<!-- Component: Rounded base sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded base sized basic text avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBasicText}
          componentName="AvatarRoundedSmBasicText"
        >
          {/*<!-- Component: Rounded sm sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded sm sized basic text avatar --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBasicText}
          componentName="AvatarRoundedXsBasicText"
        >
          {/*<!-- Component: Rounded xs sized basic text avatar --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded xs sized basic text avatar --> */}
        </CopyComponent>
        {/*<!-- End All rounded basic text avatars --> */}
      </div>
    </div>
  )
}
