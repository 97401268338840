import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedAllTooltipPreview(props) {
  const {
    AvatarRoundedLgTooltipText,
    AvatarRoundedLgTooltipImg,
    AvatarRoundedLgTooltipIcon,
    AvatarRoundedBaseTooltipText,
    AvatarRoundedBaseTooltipImg,
    AvatarRoundedBaseTooltipIcon,
    AvatarRoundedSmTooltipText,
    AvatarRoundedSmTooltipImg,
    AvatarRoundedSmTooltipIcon,
    AvatarRoundedXsTooltipText,
    AvatarRoundedXsTooltipImg,
    AvatarRoundedXsTooltipIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded img avatars with tooltip --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgTooltipImg}
          componentName="AvatarRoundedLgTooltipImg"
        >
          {/*<!-- Component: Rounded lg sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/48?img=1"
                alt="User's Name"
                title="User's Name"
                width=" 48"
                height="48"
                className="max-w-full rounded"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized img avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseTooltipImg}
          componentName="AvatarRoundedBaseTooltipImg"
        >
          {/*<!-- Component: Rounded base sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/40?img=3"
                alt="user name"
                title="user name"
                width="40"
                height="40"
                className="max-w-full rounded"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized img avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmTooltipImg}
          componentName="AvatarRoundedSmTooltipImg"
        >
          {/*<!-- Component: Rounded sm sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            data-text="John Smith"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/32?img=4"
                alt="user name"
                title="user name"
                width="32"
                height="32"
                className="max-w-full rounded"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized img avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsTooltipImg}
          componentName="AvatarRoundedXsTooltipImg"
        >
          {/*<!-- Component: Rounded sx sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/24?img=5"
                alt="user name"
                title="user name"
                width="24"
                height="24"
                className="max-w-full rounded"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded sx sized img avatars with tooltip --> */}
        </CopyComponent>
        {/*<!-- End All rounded img avatars with tooltip --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded icon avatars with tooltip --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgTooltipIcon}
          componentName="AvatarRoundedLgTooltipIcon"
        >
          {/*<!-- Component: Rounded lg sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-51 desc-51"
                role="graphics-symbol"
              >
                <title id="title-51">User Icon</title>
                <desc id="desc-51">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded lg sized icon avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseTooltipIcon}
          componentName="AvatarRoundedBaseTooltipIcon"
        >
          {/*<!-- Component: Rounded base sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-52 desc-52"
                role="graphics-symbol"
              >
                <title id="title-52">User Icon</title>
                <desc id="desc-52">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded base sized icon avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmTooltipIcon}
          componentName="AvatarRoundedSmTooltipIcon"
        >
          {/*<!-- Component: Rounded sm sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-53 desc-53"
                role="graphics-symbol"
              >
                <title id="title-53">User Icon</title>
                <desc id="desc-53">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded sm sized icon avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsTooltipIcon}
          componentName="AvatarRoundedXsTooltipIcon"
        >
          {/*<!-- Component: Rounded xs sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-54 desc-54"
                role="graphics-symbol"
              >
                <title id="title-54">User Icon</title>
                <desc id="desc-54">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded xs sized icon avatars with tooltip --> */}
        </CopyComponent>
        {/*<!-- End All rounded icon avatars with tooltip --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded text avatars with tooltip --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgTooltipText}
          componentName="AvatarRoundedLgTooltipText"
        >
          {/*<!-- Component: Rounded lg sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded lg sized text avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseTooltipText}
          componentName="AvatarRoundedBaseTooltipText"
        >
          {/*<!-- Component: Rounded base sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded base sized text avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmTooltipText}
          componentName="AvatarRoundedSmTooltipText"
        >
          {/*<!-- Component: Rounded sm sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded sm sized text avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsTooltipText}
          componentName="AvatarRoundedXsTooltipText"
        >
          {/*<!-- Component: Rounded xs sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded xs sized text avatars with tooltip --> */}
        </CopyComponent>
        {/*<!-- End All rounded text avatars with tooltip --> */}
      </div>
    </div>
  )
}
