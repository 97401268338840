import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllIndicatorBadgeBottomPreview(props) {
  const {
    AvatarRoundedFullLgIndicatorBadgeBottomText,
    AvatarRoundedFullLgIndicatorBadgeBottomImg,
    AvatarRoundedFullLgIndicatorBadgeBottomIcon,
    AvatarRoundedFullBaseIndicatorBadgeBottomText,
    AvatarRoundedFullBaseIndicatorBadgeBottomImg,
    AvatarRoundedFullBaseIndicatorBadgeBottomIcon,
    AvatarRoundedFullSmIndicatorBadgeBottomText,
    AvatarRoundedFullSmIndicatorBadgeBottomImg,
    AvatarRoundedFullSmIndicatorBadgeBottomIcon,
    AvatarRoundedFullXsIndicatorBadgeBottomText,
    AvatarRoundedFullXsIndicatorBadgeBottomImg,
    AvatarRoundedFullXsIndicatorBadgeBottomIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full bottom indicator badge img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgIndicatorBadgeBottomImg}
          componentName="AvatarRoundedFullLgIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full lg sized img avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=30"
              alt="user name"
              title="user name"
              width=" 48"
              height=" 48"
              className="max-w-full rounded-full"
            />
            <span className="absolute bottom-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseIndicatorBadgeBottomImg}
          componentName="AvatarRoundedFullBaseIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full base sized img avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=31"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded-full "
            />
            <span className="absolute bottom-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white ">
              <span className="sr-only "> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmIndicatorBadgeBottomImg}
          componentName="AvatarRoundedFullSmIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full sm sized img avatars with bottom right indicator badge --> */}
          <a
            href=" #"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=32"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded-full "
            />
            <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsIndicatorBadgeBottomImg}
          componentName="AvatarRoundedFullXsIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full xs sized img avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=33"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded-full "
            />
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full bottom indicator badge img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full bottom indicator badge icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedFullLgIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-35 desc-35"
              role="graphics-symbol"
            >
              <title id="title-35">User Icon</title>
              <desc id="desc-35">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute bottom-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedFullBaseIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full base sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-36 desc-36"
              role="graphics-symbol"
            >
              <title id="title-36">User Icon</title>
              <desc id="desc-36">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute bottom-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedFullSmIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full sm sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-37 desc-37"
              role="graphics-symbol"
            >
              <title id="title-37">User Icon</title>
              <desc id="desc-37">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedFullXsIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full xs sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-38 desc-38"
              role="graphics-symbol"
            >
              <title id="title-38">User Icon</title>
              <desc id="desc-38">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full bottom indicator badge icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full bottom indicator badge text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgIndicatorBadgeBottomText}
          componentName="AvatarRoundedFullLgIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded full lg sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute bottom-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseIndicatorBadgeBottomText}
          componentName="AvatarRoundedFullBaseIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded full base sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute bottom-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmIndicatorBadgeBottomText}
          componentName="AvatarRoundedFullSmIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded full sm sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsIndicatorBadgeBottomText}
          componentName="AvatarRoundedFullXsIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded full xs sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>
        {/*<!-- Start All rounded full bottom indicator badge text avatars --> */}
      </div>
    </div>
  )
}
