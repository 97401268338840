import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllBadgeTopPreview(props) {
  const {
    AvatarRoundedFullLgBadgeTopText,
    AvatarRoundedFullLgBadgeTopImg,
    AvatarRoundedFullLgBadgeTopIcon,
    AvatarRoundedFullBaseBadgeTopText,
    AvatarRoundedFullBaseBadgeTopImg,
    AvatarRoundedFullBaseBadgeTopIcon,
    AvatarRoundedFullSmBadgeTopText,
    AvatarRoundedFullSmBadgeTopImg,
    AvatarRoundedFullSmBadgeTopIcon,
    AvatarRoundedFullXsBadgeTopText,
    AvatarRoundedFullXsBadgeTopImg,
    AvatarRoundedFullXsBadgeTopIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full top badge img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBadgeTopImg}
          componentName="AvatarRoundedFullLgBadgeTopImg"
        >
          {/*<!-- Component: Rounded full lg sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=14"
              alt="user name"
              title="user name"
              width=" 48"
              height=" 48"
              className="max-w-full rounded-full"
            />
            <span className="absolute -top-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized img avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBadgeTopImg}
          componentName="AvatarRoundedFullBaseBadgeTopImg"
        >
          {/*<!-- Component: Rounded full base sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=15"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded-full "
            />
            <span className=" absolute -top-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized img avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBadgeTopImg}
          componentName="AvatarRoundedFullSmBadgeTopImg"
        >
          {/*<!-- Component: Rounded full sm sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=16"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded-full "
            />
            <span className=" absolute -top-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized img avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBadgeTopImg}
          componentName="AvatarRoundedFullXsBadgeTopImg"
        >
          {/*<!-- Component: Rounded full xs sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=17"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded-full "
            />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized img avatars with top right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full base sized top badge img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full top badge icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBadgeTopIcon}
          componentName="AvatarRoundedFullLgBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-25 desc-25"
              role="graphics-symbol"
            >
              <title id="title-25">User Icon</title>
              <desc id="desc-25">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized icon avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBadgeTopIcon}
          componentName="AvatarRoundedFullBaseBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full base sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-26 desc-26"
              role="graphics-symbol"
            >
              <title id="title-26">User Icon</title>
              <desc id="desc-26">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized icon avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBadgeTopIcon}
          componentName="AvatarRoundedFullSmBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full sm sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-27 desc-27"
              role="graphics-symbol"
            >
              <title id="title-27">User Icon</title>
              <desc id="desc-27">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized icon avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBadgeTopIcon}
          componentName="AvatarRoundedFullXsBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full xs sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-28 desc-28"
              role="graphics-symbol"
            >
              <title id="title-28">User Icon</title>
              <desc id="desc-28">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized icon avatars with top right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full base sized top badge icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full top badge text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBadgeTopText}
          componentName="AvatarRoundedFullLgBadgeTopText"
        >
          {/*<!-- Component: Rounded full lg sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -top-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized text avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBadgeTopText}
          componentName="AvatarRoundedFullBaseBadgeTopText"
        >
          {/*<!-- Component: Rounded full base sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -top-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized text avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBadgeTopText}
          componentName="AvatarRoundedFullSmBadgeTopText"
        >
          {/*<!-- Component: Rounded full sm sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -top-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized text avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBadgeTopText}
          componentName="AvatarRoundedFullXsBadgeTopText"
        >
          {/*<!-- Component: Rounded full xs sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized text avatars with top right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full base sized top badge text avatars --> */}
      </div>
    </div>
  )
}
