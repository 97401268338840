import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedAllBadgeBottomPreview(props) {
  const {
    AvatarRoundedLgBadgeBottomText,
    AvatarRoundedLgBadgeBottomImg,
    AvatarRoundedLgBadgeBottomIcon,
    AvatarRoundedBaseBadgeBottomText,
    AvatarRoundedBaseBadgeBottomImg,
    AvatarRoundedBaseBadgeBottomIcon,
    AvatarRoundedSmBadgeBottomText,
    AvatarRoundedSmBadgeBottomImg,
    AvatarRoundedSmBadgeBottomIcon,
    AvatarRoundedXsBadgeBottomText,
    AvatarRoundedXsBadgeBottomImg,
    AvatarRoundedXsBadgeBottomIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded badge bottom img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBadgeBottomImg}
          componentName="AvatarRoundedLgBadgeBottomImg"
        >
          {/*<!-- Component: Rounded lg sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=18"
              alt="user name"
              title="user name"
              width=" 48"
              height="48"
              className="max-w-full rounded"
            />
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized img avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBadgeBottomImg}
          componentName="AvatarRoundedBaseBadgeBottomImg"
        >
          {/*<!-- Component: Rounded base sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=19"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded "
            />
            <span className=" absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized img avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBadgeBottomImg}
          componentName="AvatarRoundedSmBadgeBottomImg"
        >
          {/*<!-- Component: Rounded sm sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=20"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded "
            />
            <span className=" absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized img avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBadgeBottomImg}
          componentName="AvatarRoundedXsBadgeBottomImg"
        >
          {/*<!-- Component: Rounded xs sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=21"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded "
            />
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized img avatars with bottom right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded base sized badge bottom img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded badge bottom icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBadgeBottomIcon}
          componentName="AvatarRoundedLgBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded lg sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-11 desc-11"
              role="graphics-symbol"
            >
              <title id="title-11">User Icon</title>
              <desc id="desc-11">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized icon avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBadgeBottomIcon}
          componentName="AvatarRoundedBaseBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded base sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-12 desc-12"
              role="graphics-symbol"
            >
              <title id="title-12">User Icon</title>
              <desc id="desc-12">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized icon avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBadgeBottomIcon}
          componentName="AvatarRoundedSmBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded sm sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-13 desc-13"
              role="graphics-symbol"
            >
              <title id="title-13">User Icon</title>
              <desc id="desc-13">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized icon avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBadgeBottomIcon}
          componentName="AvatarRoundedXsBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded xs sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-14 desc-14"
              role="graphics-symbol"
            >
              <title id="title-14">User Icon</title>
              <desc id="desc-14">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized icon avatars with bottom right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded base sized badge bottom icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded badge bottom text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBadgeBottomText}
          componentName="AvatarRoundedLgBadgeBottomText"
        >
          {/*<!-- Component: Rounded lg sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized text avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBadgeBottomText}
          componentName="AvatarRoundedBaseBadgeBottomText"
        >
          {/*<!-- Component: Rounded base sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized text avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBadgeBottomText}
          componentName="AvatarRoundedSmBadgeBottomText"
        >
          {/*<!-- Component: Rounded sm sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized text avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBadgeBottomText}
          componentName="AvatarRoundedXsBadgeBottomText"
        >
          {/*<!-- Component: Rounded xs sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized text avatars with bottom right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded base sized badge bottom text avatars --> */}
      </div>
    </div>
  )
}
