import React, { useState } from "react"
import { Link } from "gatsby"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
import AvatarHeroComponent from "../../../library/components/avatar/react/_preview/AvatarHeroComponent"
// Preview
// Rounded
import AvatarRoundedAllBasicPreview from "../../../library/components/avatar/react/_preview/rounded/basic"
import AvatarRoundedAllBordersPreview from "../../../library/components/avatar/react/_preview/rounded/borders"
import AvatarRoundedAllBadgeTopPreview from "../../../library/components/avatar/react/_preview/rounded/badge_top"
import AvatarRoundedAllBadgeBottomPreview from "../../../library/components/avatar/react/_preview/rounded/badge_bottom"
import AvatarRoundedAllIndicatorBadgeTopPreview from "../../../library/components/avatar/react/_preview/rounded/indicator_badge_top"
import AvatarRoundedAllIndicatorBadgeBottomPreview from "../../../library/components/avatar/react/_preview/rounded/indicator_badge_bottom"
import AvatarRoundedAllTooltipPreview from "../../../library/components/avatar/react/_preview/rounded/tooltip"

//  Rounded Full
import AvatarRoundedFullAllBasicPreview from "../../../library/components/avatar/react/_preview/rounded-full/basic"
import AvatarRoundedFullAllBordersPreview from "../../../library/components/avatar/react/_preview/rounded-full/borders"
import AvatarRoundedFullAllBadgeTopPreview from "../../../library/components/avatar/react/_preview/rounded-full/badge_top"
import AvatarRoundedFullAllBadgeBottomPreview from "../../../library/components/avatar/react/_preview/rounded-full/badge_bottom"
import AvatarRoundedFullAllIndicatorBadgeTopPreview from "../../../library/components/avatar/react/_preview/rounded-full/indicator_badge_top"
import AvatarRoundedFullAllIndicatorBadgeBottomPreview from "../../../library/components/avatar/react/_preview/rounded-full/indicator_badge_bottom"
import AvatarRoundedFullAllTooltipPreview from "../../../library/components/avatar/react/_preview/rounded-full/tooltip"
import AvatarRoundedFullAllGroupedPreview from "../../../library/components/avatar/react/_preview/rounded-full/grouped"

//
import ogImage from "../../../images/seo/avatars/windui-avatars-promo-facebook.png"
import twitterImage from "../../../images/seo/avatars/windui-avatars-promo-twitter.png"

// Base
const AvatarRoundedBaseBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/basic_text.jsx")
const AvatarRoundedBaseBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/basic_text.html")

const AvatarRoundedBaseBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/basic_img.jsx")
const AvatarRoundedBaseBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/basic_img.html")

const AvatarRoundedBaseBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/basic_icon.jsx")
const AvatarRoundedBaseBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/basic_icon.html")

const AvatarRoundedBaseBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/border_text.jsx")
const AvatarRoundedBaseBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/border_text.html")

const AvatarRoundedBaseBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/border_img.jsx")
const AvatarRoundedBaseBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/border_img.html")

const AvatarRoundedBaseBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/border_icon.jsx")
const AvatarRoundedBaseBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/border_icon.html")

const AvatarRoundedBaseBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/badge_top_text.jsx")
const AvatarRoundedBaseBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/badge_top_text.html")

const AvatarRoundedBaseBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/badge_top_img.jsx")
const AvatarRoundedBaseBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/badge_top_img.html")

const AvatarRoundedBaseBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/badge_top_icon.jsx")
const AvatarRoundedBaseBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/badge_top_icon.html")

const AvatarRoundedBaseBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/badge_bottom_text.jsx")
const AvatarRoundedBaseBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/badge_bottom_text.html")

const AvatarRoundedBaseBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/badge_bottom_img.jsx")
const AvatarRoundedBaseBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/badge_bottom_img.html")

const AvatarRoundedBaseBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/badge_bottom_icon.jsx")
const AvatarRoundedBaseBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/badge_bottom_icon.html")

const AvatarRoundedBaseIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/indicator_badge_top_text.jsx")
const AvatarRoundedBaseIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/indicator_badge_top_text.html")

const AvatarRoundedBaseIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/indicator_badge_top_img.jsx")
const AvatarRoundedBaseIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/indicator_badge_top_img.html")

const AvatarRoundedBaseIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/indicator_badge_top_icon.jsx")
const AvatarRoundedBaseIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/indicator_badge_top_icon.html")

const AvatarRoundedBaseIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/indicator_badge_bottom_text.jsx")
const AvatarRoundedBaseIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/indicator_badge_bottom_text.html")

const AvatarRoundedBaseIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/indicator_badge_bottom_img.jsx")
const AvatarRoundedBaseIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/indicator_badge_bottom_img.html")

const AvatarRoundedBaseIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/indicator_badge_bottom_icon.jsx")
const AvatarRoundedBaseIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/indicator_badge_bottom_icon.html")

const AvatarRoundedBaseTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/tooltip_text.jsx")
const AvatarRoundedBaseTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/tooltip_text.html")

const AvatarRoundedBaseTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/tooltip_img.jsx")
const AvatarRoundedBaseTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/tooltip_img.html")

const AvatarRoundedBaseTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/base/tooltip_icon.jsx")
const AvatarRoundedBaseTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/base/tooltip_icon.html")

// lg
const AvatarRoundedLgBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/basic_text.jsx")
const AvatarRoundedLgBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/basic_text.html")

const AvatarRoundedLgBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/basic_img.jsx")
const AvatarRoundedLgBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/basic_img.html")

const AvatarRoundedLgBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/basic_icon.jsx")
const AvatarRoundedLgBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/basic_icon.html")

const AvatarRoundedLgBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/border_text.jsx")
const AvatarRoundedLgBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/border_text.html")

const AvatarRoundedLgBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/border_img.jsx")
const AvatarRoundedLgBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/border_img.html")

const AvatarRoundedLgBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/border_icon.jsx")
const AvatarRoundedLgBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/border_icon.html")

const AvatarRoundedLgBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/badge_top_text.jsx")
const AvatarRoundedLgBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/badge_top_text.html")

const AvatarRoundedLgBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/badge_top_img.jsx")
const AvatarRoundedLgBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/badge_top_img.html")

const AvatarRoundedLgBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/badge_top_icon.jsx")
const AvatarRoundedLgBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/badge_top_icon.html")

const AvatarRoundedLgBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/badge_bottom_text.jsx")
const AvatarRoundedLgBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/badge_bottom_text.html")

const AvatarRoundedLgBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/badge_bottom_img.jsx")
const AvatarRoundedLgBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/badge_bottom_img.html")

const AvatarRoundedLgBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/badge_bottom_icon.jsx")
const AvatarRoundedLgBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/badge_bottom_icon.html")

const AvatarRoundedLgIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/indicator_badge_top_text.jsx")
const AvatarRoundedLgIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/indicator_badge_top_text.html")

const AvatarRoundedLgIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/indicator_badge_top_img.jsx")
const AvatarRoundedLgIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/indicator_badge_top_img.html")

const AvatarRoundedLgIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/indicator_badge_top_icon.jsx")
const AvatarRoundedLgIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/indicator_badge_top_icon.html")

const AvatarRoundedLgIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/indicator_badge_bottom_text.jsx")
const AvatarRoundedLgIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/indicator_badge_bottom_text.html")

const AvatarRoundedLgIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/indicator_badge_bottom_img.jsx")
const AvatarRoundedLgIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/indicator_badge_bottom_img.html")

const AvatarRoundedLgIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/indicator_badge_bottom_icon.jsx")
const AvatarRoundedLgIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/indicator_badge_bottom_icon.html")

const AvatarRoundedLgTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/tooltip_text.jsx")
const AvatarRoundedLgTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/tooltip_text.html")

const AvatarRoundedLgTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/tooltip_img.jsx")
const AvatarRoundedLgTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/tooltip_img.html")

const AvatarRoundedLgTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/lg/tooltip_icon.jsx")
const AvatarRoundedLgTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/lg/tooltip_icon.html")

// Sm
const AvatarRoundedSmBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/basic_text.jsx")
const AvatarRoundedSmBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/basic_text.html")

const AvatarRoundedSmBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/basic_img.jsx")
const AvatarRoundedSmBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/basic_img.html")

const AvatarRoundedSmBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/basic_icon.jsx")
const AvatarRoundedSmBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/basic_icon.html")

const AvatarRoundedSmBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/border_text.jsx")
const AvatarRoundedSmBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/border_text.html")

const AvatarRoundedSmBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/border_img.jsx")
const AvatarRoundedSmBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/border_img.html")

const AvatarRoundedSmBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/border_icon.jsx")
const AvatarRoundedSmBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/border_icon.html")

const AvatarRoundedSmBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/badge_top_text.jsx")
const AvatarRoundedSmBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/badge_top_text.html")

const AvatarRoundedSmBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/badge_top_img.jsx")
const AvatarRoundedSmBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/badge_top_img.html")

const AvatarRoundedSmBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/badge_top_icon.jsx")
const AvatarRoundedSmBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/badge_top_icon.html")

const AvatarRoundedSmBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/badge_bottom_text.jsx")
const AvatarRoundedSmBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/badge_bottom_text.html")

const AvatarRoundedSmBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/badge_bottom_img.jsx")
const AvatarRoundedSmBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/badge_bottom_img.html")

const AvatarRoundedSmBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/badge_bottom_icon.jsx")
const AvatarRoundedSmBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/badge_bottom_icon.html")

const AvatarRoundedSmIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/indicator_badge_top_text.jsx")
const AvatarRoundedSmIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/indicator_badge_top_text.html")

const AvatarRoundedSmIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/indicator_badge_top_img.jsx")
const AvatarRoundedSmIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/indicator_badge_top_img.html")

const AvatarRoundedSmIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/indicator_badge_top_icon.jsx")
const AvatarRoundedSmIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/indicator_badge_top_icon.html")

const AvatarRoundedSmIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/indicator_badge_bottom_text.jsx")
const AvatarRoundedSmIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/indicator_badge_bottom_text.html")

const AvatarRoundedSmIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/indicator_badge_bottom_img.jsx")
const AvatarRoundedSmIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/indicator_badge_bottom_img.html")

const AvatarRoundedSmIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/indicator_badge_bottom_icon.jsx")
const AvatarRoundedSmIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/indicator_badge_bottom_icon.html")

const AvatarRoundedSmTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/tooltip_text.jsx")
const AvatarRoundedSmTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/tooltip_text.html")

const AvatarRoundedSmTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/tooltip_img.jsx")
const AvatarRoundedSmTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/tooltip_img.html")

const AvatarRoundedSmTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/sm/tooltip_icon.jsx")
const AvatarRoundedSmTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/sm/tooltip_icon.html")

// Xs
const AvatarRoundedXsBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/basic_text.jsx")
const AvatarRoundedXsBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/basic_text.html")

const AvatarRoundedXsBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/basic_img.jsx")
const AvatarRoundedXsBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/basic_img.html")

const AvatarRoundedXsBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/basic_icon.jsx")
const AvatarRoundedXsBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/basic_icon.html")

const AvatarRoundedXsBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/border_text.jsx")
const AvatarRoundedXsBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/border_text.html")

const AvatarRoundedXsBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/border_img.jsx")
const AvatarRoundedXsBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/border_img.html")

const AvatarRoundedXsBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/border_icon.jsx")
const AvatarRoundedXsBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/border_icon.html")

const AvatarRoundedXsBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/badge_top_text.jsx")
const AvatarRoundedXsBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/badge_top_text.html")

const AvatarRoundedXsBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/badge_top_img.jsx")
const AvatarRoundedXsBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/badge_top_img.html")

const AvatarRoundedXsBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/badge_top_icon.jsx")
const AvatarRoundedXsBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/badge_top_icon.html")

const AvatarRoundedXsBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/badge_bottom_text.jsx")
const AvatarRoundedXsBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/badge_bottom_text.html")

const AvatarRoundedXsBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/badge_bottom_img.jsx")
const AvatarRoundedXsBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/badge_bottom_img.html")

const AvatarRoundedXsBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/badge_bottom_icon.jsx")
const AvatarRoundedXsBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/badge_bottom_icon.html")

const AvatarRoundedXsIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/indicator_badge_top_text.jsx")
const AvatarRoundedXsIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/indicator_badge_top_text.html")

const AvatarRoundedXsIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/indicator_badge_top_img.jsx")
const AvatarRoundedXsIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/indicator_badge_top_img.html")

const AvatarRoundedXsIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/indicator_badge_top_icon.jsx")
const AvatarRoundedXsIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/indicator_badge_top_icon.html")

const AvatarRoundedXsIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/indicator_badge_bottom_text.jsx")
const AvatarRoundedXsIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/indicator_badge_bottom_text.html")

const AvatarRoundedXsIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/indicator_badge_bottom_img.jsx")
const AvatarRoundedXsIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/indicator_badge_bottom_img.html")

const AvatarRoundedXsIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/indicator_badge_bottom_icon.jsx")
const AvatarRoundedXsIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/indicator_badge_bottom_icon.html")

const AvatarRoundedXsTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/tooltip_text.jsx")
const AvatarRoundedXsTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/tooltip_text.html")

const AvatarRoundedXsTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/tooltip_img.jsx")
const AvatarRoundedXsTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/tooltip_img.html")

const AvatarRoundedXsTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded/xs/tooltip_icon.jsx")
const AvatarRoundedXsTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded/xs/tooltip_icon.html")

// Base
const AvatarRoundedFullBaseBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/basic_text.jsx")
const AvatarRoundedFullBaseBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/basic_text.html")

const AvatarRoundedFullBaseBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/basic_img.jsx")
const AvatarRoundedFullBaseBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/basic_img.html")

const AvatarRoundedFullBaseBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/basic_icon.jsx")
const AvatarRoundedFullBaseBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/basic_icon.html")

const AvatarRoundedFullBaseBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/border_text.jsx")
const AvatarRoundedFullBaseBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/border_text.html")

const AvatarRoundedFullBaseBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/border_img.jsx")
const AvatarRoundedFullBaseBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/border_img.html")

const AvatarRoundedFullBaseBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/border_icon.jsx")
const AvatarRoundedFullBaseBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/border_icon.html")

const AvatarRoundedFullBaseBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/badge_top_text.jsx")
const AvatarRoundedFullBaseBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/badge_top_text.html")

const AvatarRoundedFullBaseBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/badge_top_img.jsx")
const AvatarRoundedFullBaseBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/badge_top_img.html")

const AvatarRoundedFullBaseBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/badge_top_icon.jsx")
const AvatarRoundedFullBaseBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/badge_top_icon.html")

const AvatarRoundedFullBaseBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/badge_bottom_text.jsx")
const AvatarRoundedFullBaseBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/badge_bottom_text.html")

const AvatarRoundedFullBaseBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/badge_bottom_img.jsx")
const AvatarRoundedFullBaseBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/badge_bottom_img.html")

const AvatarRoundedFullBaseBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/badge_bottom_icon.jsx")
const AvatarRoundedFullBaseBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/badge_bottom_icon.html")

const AvatarRoundedFullBaseIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/indicator_badge_top_text.jsx")
const AvatarRoundedFullBaseIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/indicator_badge_top_text.html")

const AvatarRoundedFullBaseIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/indicator_badge_top_img.jsx")
const AvatarRoundedFullBaseIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/indicator_badge_top_img.html")

const AvatarRoundedFullBaseIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/indicator_badge_top_icon.jsx")
const AvatarRoundedFullBaseIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/indicator_badge_top_icon.html")

const AvatarRoundedFullBaseIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/indicator_badge_bottom_text.jsx")
const AvatarRoundedFullBaseIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/indicator_badge_bottom_text.html")

const AvatarRoundedFullBaseIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/indicator_badge_bottom_img.jsx")
const AvatarRoundedFullBaseIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/indicator_badge_bottom_img.html")

const AvatarRoundedFullBaseIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/indicator_badge_bottom_icon.jsx")
const AvatarRoundedFullBaseIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/indicator_badge_bottom_icon.html")

const AvatarRoundedFullBaseTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/tooltip_text.jsx")
const AvatarRoundedFullBaseTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/tooltip_text.html")

const AvatarRoundedFullBaseTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/tooltip_img.jsx")
const AvatarRoundedFullBaseTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/tooltip_img.html")

const AvatarRoundedFullBaseTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/tooltip_icon.jsx")
const AvatarRoundedFullBaseTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/tooltip_icon.html")

const AvatarRoundedFullBaseGroupedBasicJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/grouped_basic.jsx")
const AvatarRoundedFullBaseGroupedBasicHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/grouped_basic.html")

const AvatarRoundedFullBaseGroupedCompactJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/grouped_compact.jsx")
const AvatarRoundedFullBaseGroupedCompactHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/grouped_compact.html")

const AvatarRoundedFullBaseGroupedTooltipJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/base/grouped_tooltip.jsx")
const AvatarRoundedFullBaseGroupedTooltipHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/base/grouped_tooltip.html")

// Lg
const AvatarRoundedFullLgBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/basic_text.jsx")
const AvatarRoundedFullLgBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/basic_text.html")

const AvatarRoundedFullLgBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/basic_img.jsx")
const AvatarRoundedFullLgBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/basic_img.html")

const AvatarRoundedFullLgBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/basic_icon.jsx")
const AvatarRoundedFullLgBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/basic_icon.html")

const AvatarRoundedFullLgBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/border_text.jsx")
const AvatarRoundedFullLgBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/border_text.html")

const AvatarRoundedFullLgBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/border_img.jsx")
const AvatarRoundedFullLgBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/border_img.html")

const AvatarRoundedFullLgBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/border_icon.jsx")
const AvatarRoundedFullLgBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/border_icon.html")

const AvatarRoundedFullLgBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/badge_top_text.jsx")
const AvatarRoundedFullLgBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/badge_top_text.html")

const AvatarRoundedFullLgBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/badge_top_img.jsx")
const AvatarRoundedFullLgBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/badge_top_img.html")

const AvatarRoundedFullLgBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/badge_top_icon.jsx")
const AvatarRoundedFullLgBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/badge_top_icon.html")

const AvatarRoundedFullLgBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/badge_bottom_text.jsx")
const AvatarRoundedFullLgBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/badge_bottom_text.html")

const AvatarRoundedFullLgBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/badge_bottom_img.jsx")
const AvatarRoundedFullLgBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/badge_bottom_img.html")

const AvatarRoundedFullLgBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/badge_bottom_icon.jsx")
const AvatarRoundedFullLgBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/badge_bottom_icon.html")

const AvatarRoundedFullLgIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/indicator_badge_top_text.jsx")
const AvatarRoundedFullLgIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/indicator_badge_top_text.html")

const AvatarRoundedFullLgIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/indicator_badge_top_img.jsx")
const AvatarRoundedFullLgIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/indicator_badge_top_img.html")

const AvatarRoundedFullLgIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/indicator_badge_top_icon.jsx")
const AvatarRoundedFullLgIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/indicator_badge_top_icon.html")

const AvatarRoundedFullLgIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/indicator_badge_bottom_text.jsx")
const AvatarRoundedFullLgIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/indicator_badge_bottom_text.html")

const AvatarRoundedFullLgIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/indicator_badge_bottom_img.jsx")
const AvatarRoundedFullLgIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/indicator_badge_bottom_img.html")

const AvatarRoundedFullLgIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/indicator_badge_bottom_icon.jsx")
const AvatarRoundedFullLgIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/indicator_badge_bottom_icon.html")

const AvatarRoundedFullLgTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/tooltip_text.jsx")
const AvatarRoundedFullLgTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/tooltip_text.html")

const AvatarRoundedFullLgTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/tooltip_img.jsx")
const AvatarRoundedFullLgTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/tooltip_img.html")

const AvatarRoundedFullLgTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/lg/tooltip_icon.jsx")
const AvatarRoundedFullLgTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/lg/tooltip_icon.html")

// Sm
const AvatarRoundedFullSmBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/basic_text.jsx")
const AvatarRoundedFullSmBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/basic_text.html")

const AvatarRoundedFullSmBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/basic_img.jsx")
const AvatarRoundedFullSmBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/basic_img.html")

const AvatarRoundedFullSmBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/basic_icon.jsx")
const AvatarRoundedFullSmBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/basic_icon.html")

const AvatarRoundedFullSmBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/border_text.jsx")
const AvatarRoundedFullSmBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/border_text.html")

const AvatarRoundedFullSmBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/border_img.jsx")
const AvatarRoundedFullSmBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/border_img.html")

const AvatarRoundedFullSmBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/border_icon.jsx")
const AvatarRoundedFullSmBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/border_icon.html")

const AvatarRoundedFullSmBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/badge_top_text.jsx")
const AvatarRoundedFullSmBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/badge_top_text.html")

const AvatarRoundedFullSmBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/badge_top_img.jsx")
const AvatarRoundedFullSmBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/badge_top_img.html")

const AvatarRoundedFullSmBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/badge_top_icon.jsx")
const AvatarRoundedFullSmBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/badge_top_icon.html")

const AvatarRoundedFullSmBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/badge_bottom_text.jsx")
const AvatarRoundedFullSmBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/badge_bottom_text.html")

const AvatarRoundedFullSmBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/badge_bottom_img.jsx")
const AvatarRoundedFullSmBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/badge_bottom_img.html")

const AvatarRoundedFullSmBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/badge_bottom_icon.jsx")
const AvatarRoundedFullSmBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/badge_bottom_icon.html")

const AvatarRoundedFullSmIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/indicator_badge_top_text.jsx")
const AvatarRoundedFullSmIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/indicator_badge_top_text.html")

const AvatarRoundedFullSmIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/indicator_badge_top_img.jsx")
const AvatarRoundedFullSmIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/indicator_badge_top_img.html")

const AvatarRoundedFullSmIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/indicator_badge_top_icon.jsx")
const AvatarRoundedFullSmIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/indicator_badge_top_icon.html")

const AvatarRoundedFullSmIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/indicator_badge_bottom_text.jsx")
const AvatarRoundedFullSmIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/indicator_badge_bottom_text.html")

const AvatarRoundedFullSmIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/indicator_badge_bottom_img.jsx")
const AvatarRoundedFullSmIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/indicator_badge_bottom_img.html")

const AvatarRoundedFullSmIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/indicator_badge_bottom_icon.jsx")
const AvatarRoundedFullSmIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/indicator_badge_bottom_icon.html")

const AvatarRoundedFullSmTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/tooltip_text.jsx")
const AvatarRoundedFullSmTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/tooltip_text.html")

const AvatarRoundedFullSmTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/tooltip_img.jsx")
const AvatarRoundedFullSmTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/tooltip_img.html")

const AvatarRoundedFullSmTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/sm/tooltip_icon.jsx")
const AvatarRoundedFullSmTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/sm/tooltip_icon.html")

// Xs
const AvatarRoundedFullXsBasicTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/basic_text.jsx")
const AvatarRoundedFullXsBasicTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/basic_text.html")

const AvatarRoundedFullXsBasicImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/basic_img.jsx")
const AvatarRoundedFullXsBasicImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/basic_img.html")

const AvatarRoundedFullXsBasicIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/basic_icon.jsx")
const AvatarRoundedFullXsBasicIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/basic_icon.html")

const AvatarRoundedFullXsBorderTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/border_text.jsx")
const AvatarRoundedFullXsBorderTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/border_text.html")

const AvatarRoundedFullXsBorderImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/border_img.jsx")
const AvatarRoundedFullXsBorderImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/border_img.html")

const AvatarRoundedFullXsBorderIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/border_icon.jsx")
const AvatarRoundedFullXsBorderIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/border_icon.html")

const AvatarRoundedFullXsBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/badge_top_text.jsx")
const AvatarRoundedFullXsBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/badge_top_text.html")

const AvatarRoundedFullXsBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/badge_top_img.jsx")
const AvatarRoundedFullXsBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/badge_top_img.html")

const AvatarRoundedFullXsBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/badge_top_icon.jsx")
const AvatarRoundedFullXsBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/badge_top_icon.html")

const AvatarRoundedFullXsBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/badge_bottom_text.jsx")
const AvatarRoundedFullXsBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/badge_bottom_text.html")

const AvatarRoundedFullXsBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/badge_bottom_img.jsx")
const AvatarRoundedFullXsBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/badge_bottom_img.html")

const AvatarRoundedFullXsBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/badge_bottom_icon.jsx")
const AvatarRoundedFullXsBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/badge_bottom_icon.html")

const AvatarRoundedFullXsIndicatorBadgeTopTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/indicator_badge_top_text.jsx")
const AvatarRoundedFullXsIndicatorBadgeTopTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/indicator_badge_top_text.html")

const AvatarRoundedFullXsIndicatorBadgeTopImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/indicator_badge_top_img.jsx")
const AvatarRoundedFullXsIndicatorBadgeTopImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/indicator_badge_top_img.html")

const AvatarRoundedFullXsIndicatorBadgeTopIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/indicator_badge_top_icon.jsx")
const AvatarRoundedFullXsIndicatorBadgeTopIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/indicator_badge_top_icon.html")

const AvatarRoundedFullXsIndicatorBadgeBottomTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/indicator_badge_bottom_text.jsx")
const AvatarRoundedFullXsIndicatorBadgeBottomTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/indicator_badge_bottom_text.html")

const AvatarRoundedFullXsIndicatorBadgeBottomImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/indicator_badge_bottom_img.jsx")
const AvatarRoundedFullXsIndicatorBadgeBottomImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/indicator_badge_bottom_img.html")

const AvatarRoundedFullXsIndicatorBadgeBottomIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/indicator_badge_bottom_icon.jsx")
const AvatarRoundedFullXsIndicatorBadgeBottomIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/indicator_badge_bottom_icon.html")

const AvatarRoundedFullXsTooltipTextJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/tooltip_text.jsx")
const AvatarRoundedFullXsTooltipTextHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/tooltip_text.html")

const AvatarRoundedFullXsTooltipImgJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/tooltip_img.jsx")
const AvatarRoundedFullXsTooltipImgHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/tooltip_img.html")

const AvatarRoundedFullXsTooltipIconJsx = require("!!raw-loader!../../../library/components/avatar/react/rounded-full/xs/tooltip_icon.jsx")
const AvatarRoundedFullXsTooltipIconHTML = require("!!raw-loader!../../../library/components/avatar/html/rounded-full/xs/tooltip_icon.html")

export default function AvatarsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "AvatarRoundedAllBasic",
      title: "Basic Avatars",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedAllBorders",
      title: "With borders",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedAllBadgeTop",
      title: "With top badge",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedAllBadgeBottom",
      title: "With bottom badge",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedAllIndicatorBadgeTop",
      title: "With top indicator",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedAllIndicatorBadgeBottom",
      title: "With bottom indicator",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedAllTooltip",
      title: "With tooltip",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllBasic",
      title: "Full rounded",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllBorders",
      title: "Full rounded with borders",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllBadgeTop",
      title: "Full rounded, top badge",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllBadgeBottom",
      title: "Full rounded, bottom badge",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllIndicatorBadgeTop",
      title: "Full rounded, top indicator",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllIndicatorBadgeBottom",
      title: "Full rounded, bottom indicator",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllTooltip",
      title: "Full rounded, tooltip",
      active_tab: 1,
    },
    {
      component_name: "AvatarRoundedFullAllGrouped",
      title: "Grouped",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Avatars - WindUI Component Library"
        ogtitle="Tailwind CSS Avatars - WindUI Component Library"
        description="Avatar component is a graphical representation of a user, that provides additional information like status or notifications. Built with Tailwind CSS by WindUI."
        ogdescription="Avatar component is a graphical representation of a user, that provides additional information like status or notifications. Built with Tailwind CSS by WindUI."
        url="components/avatars/"
        ogImage={ogImage}
        twitterImage={twitterImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Avatars, Avatars, Avatar Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Avatars</h1>
          <p>
            An Avatar is a graphical representation of a user. The user can be
            represented by an image, an icon or the user initials. Avatars can
            provide additional information like the user status or user
            notifications.
          </p>
          {/* Hero Preview Section */}
          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <AvatarHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="AvatarRoundedAllBasic">Rounded avatars</h3>

          <PreviewBlock
            id="AvatarRoundedAllBasic"
            JsxComponent={AvatarRoundedBaseBasicTextJsx.default}
            HtmlComponent={AvatarRoundedBaseBasicTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedAllBasicPreview
                AvatarRoundedBaseBasicText={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedBaseBasicTextJsx.default
                    : AvatarRoundedBaseBasicTextHTML.default
                }
                AvatarRoundedBaseBasicImg={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedBaseBasicImgJsx.default
                    : AvatarRoundedBaseBasicImgHTML.default
                }
                AvatarRoundedBaseBasicIcon={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedBaseBasicIconJsx.default
                    : AvatarRoundedBaseBasicIconHTML.default
                }
                AvatarRoundedLgBasicText={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedLgBasicTextJsx.default
                    : AvatarRoundedLgBasicTextHTML.default
                }
                AvatarRoundedLgBasicImg={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedLgBasicImgJsx.default
                    : AvatarRoundedLgBasicImgHTML.default
                }
                AvatarRoundedLgBasicIcon={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedLgBasicIconJsx.default
                    : AvatarRoundedLgBasicIconHTML.default
                }
                AvatarRoundedSmBasicText={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedSmBasicTextJsx.default
                    : AvatarRoundedSmBasicTextHTML.default
                }
                AvatarRoundedSmBasicImg={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedSmBasicImgJsx.default
                    : AvatarRoundedSmBasicImgHTML.default
                }
                AvatarRoundedSmBasicIcon={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedSmBasicIconJsx.default
                    : AvatarRoundedSmBasicIconHTML.default
                }
                AvatarRoundedXsBasicText={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedXsBasicTextJsx.default
                    : AvatarRoundedXsBasicTextHTML.default
                }
                AvatarRoundedXsBasicImg={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedXsBasicImgJsx.default
                    : AvatarRoundedXsBasicImgHTML.default
                }
                AvatarRoundedXsBasicIcon={
                  activeTabs[0].active_tab !== 1
                    ? AvatarRoundedXsBasicIconJsx.default
                    : AvatarRoundedXsBasicIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedAllBorders">Rounded avatars with borders</h3>

          <PreviewBlock
            id="AvatarRoundedAllBorders"
            JsxComponent={AvatarRoundedBaseBorderTextJsx.default}
            HtmlComponent={AvatarRoundedBaseBorderTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-700 p-8 sm:p-16">
              <AvatarRoundedAllBordersPreview
                AvatarRoundedBaseBorderText={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedBaseBorderTextJsx.default
                    : AvatarRoundedBaseBorderTextHTML.default
                }
                AvatarRoundedBaseBorderImg={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedBaseBorderImgJsx.default
                    : AvatarRoundedBaseBorderImgHTML.default
                }
                AvatarRoundedBaseBorderIcon={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedBaseBorderIconJsx.default
                    : AvatarRoundedBaseBorderIconHTML.default
                }
                AvatarRoundedLgBorderText={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedLgBorderTextJsx.default
                    : AvatarRoundedLgBorderTextHTML.default
                }
                AvatarRoundedLgBorderImg={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedLgBorderImgJsx.default
                    : AvatarRoundedLgBorderImgHTML.default
                }
                AvatarRoundedLgBorderIcon={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedLgBorderIconJsx.default
                    : AvatarRoundedLgBorderIconHTML.default
                }
                AvatarRoundedSmBorderText={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedSmBorderTextJsx.default
                    : AvatarRoundedSmBorderTextHTML.default
                }
                AvatarRoundedSmBorderImg={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedSmBorderImgJsx.default
                    : AvatarRoundedSmBorderImgHTML.default
                }
                AvatarRoundedSmBorderIcon={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedSmBorderIconJsx.default
                    : AvatarRoundedSmBorderIconHTML.default
                }
                AvatarRoundedXsBorderText={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedXsBorderTextJsx.default
                    : AvatarRoundedXsBorderTextHTML.default
                }
                AvatarRoundedXsBorderImg={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedXsBorderImgJsx.default
                    : AvatarRoundedXsBorderImgHTML.default
                }
                AvatarRoundedXsBorderIcon={
                  activeTabs[1].active_tab !== 1
                    ? AvatarRoundedXsBorderIconJsx.default
                    : AvatarRoundedXsBorderIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedAllBadgeTop">Rounded avatars with top badge</h3>

          <PreviewBlock
            id="AvatarRoundedAllBadgeTop"
            JsxComponent={AvatarRoundedBaseBadgeTopTextJsx.default}
            HtmlComponent={AvatarRoundedBaseBadgeTopTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedAllBadgeTopPreview
                AvatarRoundedBaseBadgeTopText={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedBaseBadgeTopTextJsx.default
                    : AvatarRoundedBaseBadgeTopTextHTML.default
                }
                AvatarRoundedBaseBadgeTopImg={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedBaseBadgeTopImgJsx.default
                    : AvatarRoundedBaseBadgeTopImgHTML.default
                }
                AvatarRoundedBaseBadgeTopIcon={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedBaseBadgeTopIconJsx.default
                    : AvatarRoundedBaseBadgeTopIconHTML.default
                }
                AvatarRoundedLgBadgeTopText={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedLgBadgeTopTextJsx.default
                    : AvatarRoundedLgBadgeTopTextHTML.default
                }
                AvatarRoundedLgBadgeTopImg={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedLgBadgeTopImgJsx.default
                    : AvatarRoundedLgBadgeTopImgHTML.default
                }
                AvatarRoundedLgBadgeTopIcon={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedLgBadgeTopIconJsx.default
                    : AvatarRoundedLgBadgeTopIconHTML.default
                }
                AvatarRoundedSmBadgeTopText={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedSmBadgeTopTextJsx.default
                    : AvatarRoundedSmBadgeTopTextHTML.default
                }
                AvatarRoundedSmBadgeTopImg={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedSmBadgeTopImgJsx.default
                    : AvatarRoundedSmBadgeTopImgHTML.default
                }
                AvatarRoundedSmBadgeTopIcon={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedSmBadgeTopIconJsx.default
                    : AvatarRoundedSmBadgeTopIconHTML.default
                }
                AvatarRoundedXsBadgeTopText={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedXsBadgeTopTextJsx.default
                    : AvatarRoundedXsBadgeTopTextHTML.default
                }
                AvatarRoundedXsBadgeTopImg={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedXsBadgeTopImgJsx.default
                    : AvatarRoundedXsBadgeTopImgHTML.default
                }
                AvatarRoundedXsBadgeTopIcon={
                  activeTabs[2].active_tab !== 1
                    ? AvatarRoundedXsBadgeTopIconJsx.default
                    : AvatarRoundedXsBadgeTopIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedAllBadgeBottom">
            Rounded avatars with bottom badge
          </h3>

          <PreviewBlock
            id="AvatarRoundedAllBadgeBottom"
            JsxComponent={AvatarRoundedBaseBadgeBottomTextJsx.default}
            HtmlComponent={AvatarRoundedBaseBadgeBottomTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedAllBadgeBottomPreview
                AvatarRoundedBaseBadgeBottomText={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedBaseBadgeBottomTextJsx.default
                    : AvatarRoundedBaseBadgeBottomTextHTML.default
                }
                AvatarRoundedBaseBadgeBottomImg={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedBaseBadgeBottomImgJsx.default
                    : AvatarRoundedBaseBadgeBottomImgHTML.default
                }
                AvatarRoundedBaseBadgeBottomIcon={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedBaseBadgeBottomIconJsx.default
                    : AvatarRoundedBaseBadgeBottomIconHTML.default
                }
                AvatarRoundedLgBadgeBottomText={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedLgBadgeBottomTextJsx.default
                    : AvatarRoundedLgBadgeBottomTextHTML.default
                }
                AvatarRoundedLgBadgeBottomImg={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedLgBadgeBottomImgJsx.default
                    : AvatarRoundedLgBadgeBottomImgHTML.default
                }
                AvatarRoundedLgBadgeBottomIcon={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedLgBadgeBottomIconJsx.default
                    : AvatarRoundedLgBadgeBottomIconHTML.default
                }
                AvatarRoundedSmBadgeBottomText={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedSmBadgeBottomTextJsx.default
                    : AvatarRoundedSmBadgeBottomTextHTML.default
                }
                AvatarRoundedSmBadgeBottomImg={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedSmBadgeBottomImgJsx.default
                    : AvatarRoundedSmBadgeBottomImgHTML.default
                }
                AvatarRoundedSmBadgeBottomIcon={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedSmBadgeBottomIconJsx.default
                    : AvatarRoundedSmBadgeBottomIconHTML.default
                }
                AvatarRoundedXsBadgeBottomText={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedXsBadgeBottomTextJsx.default
                    : AvatarRoundedXsBadgeBottomTextHTML.default
                }
                AvatarRoundedXsBadgeBottomImg={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedXsBadgeBottomImgJsx.default
                    : AvatarRoundedXsBadgeBottomImgHTML.default
                }
                AvatarRoundedXsBadgeBottomIcon={
                  activeTabs[3].active_tab !== 1
                    ? AvatarRoundedXsBadgeBottomIconJsx.default
                    : AvatarRoundedXsBadgeBottomIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedAllIndicatorBadgeTop">
            Rounded avatars with top indicator
          </h3>

          <PreviewBlock
            id="AvatarRoundedAllIndicatorBadgeTop"
            JsxComponent={AvatarRoundedBaseIndicatorBadgeTopTextJsx.default}
            HtmlComponent={AvatarRoundedBaseIndicatorBadgeTopTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedAllIndicatorBadgeTopPreview
                AvatarRoundedBaseIndicatorBadgeTopText={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedBaseIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedBaseIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedBaseIndicatorBadgeTopImg={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedBaseIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedBaseIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedBaseIndicatorBadgeTopIcon={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedBaseIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedBaseIndicatorBadgeTopIconHTML.default
                }
                AvatarRoundedLgIndicatorBadgeTopText={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedLgIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedLgIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedLgIndicatorBadgeTopImg={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedLgIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedLgIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedLgIndicatorBadgeTopIcon={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedLgIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedLgIndicatorBadgeTopIconHTML.default
                }
                AvatarRoundedSmIndicatorBadgeTopText={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedSmIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedSmIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedSmIndicatorBadgeTopImg={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedSmIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedSmIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedSmIndicatorBadgeTopIcon={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedSmIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedSmIndicatorBadgeTopIconHTML.default
                }
                AvatarRoundedXsIndicatorBadgeTopText={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedXsIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedXsIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedXsIndicatorBadgeTopImg={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedXsIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedXsIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedXsIndicatorBadgeTopIcon={
                  activeTabs[4].active_tab !== 1
                    ? AvatarRoundedXsIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedXsIndicatorBadgeTopIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedAllIndicatorBadgeBottom">
            Rounded avatars with bottom indicator
          </h3>

          <PreviewBlock
            id="AvatarRoundedAllIndicatorBadgeBottom"
            JsxComponent={AvatarRoundedBaseIndicatorBadgeBottomTextJsx.default}
            HtmlComponent={
              AvatarRoundedBaseIndicatorBadgeBottomTextHTML.default
            }
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedAllIndicatorBadgeBottomPreview
                AvatarRoundedBaseIndicatorBadgeBottomText={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedBaseIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedBaseIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedBaseIndicatorBadgeBottomImg={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedBaseIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedBaseIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedBaseIndicatorBadgeBottomIcon={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedBaseIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedBaseIndicatorBadgeBottomIconHTML.default
                }
                AvatarRoundedLgIndicatorBadgeBottomText={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedLgIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedLgIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedLgIndicatorBadgeBottomImg={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedLgIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedLgIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedLgIndicatorBadgeBottomIcon={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedLgIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedLgIndicatorBadgeBottomIconHTML.default
                }
                AvatarRoundedSmIndicatorBadgeBottomText={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedSmIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedSmIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedSmIndicatorBadgeBottomImg={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedSmIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedSmIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedSmIndicatorBadgeBottomIcon={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedSmIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedSmIndicatorBadgeBottomIconHTML.default
                }
                AvatarRoundedXsIndicatorBadgeBottomText={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedXsIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedXsIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedXsIndicatorBadgeBottomImg={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedXsIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedXsIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedXsIndicatorBadgeBottomIcon={
                  activeTabs[5].active_tab !== 1
                    ? AvatarRoundedXsIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedXsIndicatorBadgeBottomIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedAllTooltip">Rounded avatars with tooltip</h3>

          <PreviewBlock
            id="AvatarRoundedAllTooltip"
            JsxComponent={AvatarRoundedBaseTooltipTextJsx.default}
            HtmlComponent={AvatarRoundedBaseTooltipTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedAllTooltipPreview
                AvatarRoundedBaseTooltipText={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedBaseTooltipTextJsx.default
                    : AvatarRoundedBaseTooltipTextHTML.default
                }
                AvatarRoundedBaseTooltipImg={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedBaseTooltipImgJsx.default
                    : AvatarRoundedBaseTooltipImgHTML.default
                }
                AvatarRoundedBaseTooltipIcon={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedBaseTooltipIconJsx.default
                    : AvatarRoundedBaseTooltipIconHTML.default
                }
                AvatarRoundedLgTooltipText={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedLgTooltipTextJsx.default
                    : AvatarRoundedLgTooltipTextHTML.default
                }
                AvatarRoundedLgTooltipImg={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedLgTooltipImgJsx.default
                    : AvatarRoundedLgTooltipImgHTML.default
                }
                AvatarRoundedLgTooltipIcon={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedLgTooltipIconJsx.default
                    : AvatarRoundedLgTooltipIconHTML.default
                }
                AvatarRoundedSmTooltipText={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedSmTooltipTextJsx.default
                    : AvatarRoundedSmTooltipTextHTML.default
                }
                AvatarRoundedSmTooltipImg={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedSmTooltipImgJsx.default
                    : AvatarRoundedSmTooltipImgHTML.default
                }
                AvatarRoundedSmTooltipIcon={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedSmTooltipIconJsx.default
                    : AvatarRoundedSmTooltipIconHTML.default
                }
                AvatarRoundedXsTooltipText={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedXsTooltipTextJsx.default
                    : AvatarRoundedXsTooltipTextHTML.default
                }
                AvatarRoundedXsTooltipImg={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedXsTooltipImgJsx.default
                    : AvatarRoundedXsTooltipImgHTML.default
                }
                AvatarRoundedXsTooltipIcon={
                  activeTabs[6].active_tab !== 1
                    ? AvatarRoundedXsTooltipIconJsx.default
                    : AvatarRoundedXsTooltipIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllBasic">Full rounded avatars</h3>

          <PreviewBlock
            id="AvatarRoundedFullAllBasic"
            JsxComponent={AvatarRoundedFullBaseBasicTextJsx.default}
            HtmlComponent={AvatarRoundedFullBaseBasicTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedFullAllBasicPreview
                AvatarRoundedFullBaseBasicText={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullBaseBasicTextJsx.default
                    : AvatarRoundedFullBaseBasicTextHTML.default
                }
                AvatarRoundedFullBaseBasicImg={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullBaseBasicImgJsx.default
                    : AvatarRoundedFullBaseBasicImgHTML.default
                }
                AvatarRoundedFullBaseBasicIcon={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullBaseBasicIconJsx.default
                    : AvatarRoundedFullBaseBasicIconHTML.default
                }
                AvatarRoundedFullLgBasicText={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullLgBasicTextJsx.default
                    : AvatarRoundedFullLgBasicTextHTML.default
                }
                AvatarRoundedFullLgBasicImg={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullLgBasicImgJsx.default
                    : AvatarRoundedFullLgBasicImgHTML.default
                }
                AvatarRoundedFullLgBasicIcon={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullLgBasicIconJsx.default
                    : AvatarRoundedFullLgBasicIconHTML.default
                }
                AvatarRoundedFullSmBasicText={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullSmBasicTextJsx.default
                    : AvatarRoundedFullSmBasicTextHTML.default
                }
                AvatarRoundedFullSmBasicImg={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullSmBasicImgJsx.default
                    : AvatarRoundedFullSmBasicImgHTML.default
                }
                AvatarRoundedFullSmBasicIcon={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullSmBasicIconJsx.default
                    : AvatarRoundedFullSmBasicIconHTML.default
                }
                AvatarRoundedFullXsBasicText={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullXsBasicTextJsx.default
                    : AvatarRoundedFullXsBasicTextHTML.default
                }
                AvatarRoundedFullXsBasicImg={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullXsBasicImgJsx.default
                    : AvatarRoundedFullXsBasicImgHTML.default
                }
                AvatarRoundedFullXsBasicIcon={
                  activeTabs[7].active_tab !== 1
                    ? AvatarRoundedFullXsBasicIconJsx.default
                    : AvatarRoundedFullXsBasicIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllBorders">
            Full rounded avatars with borders
          </h3>

          <PreviewBlock
            id="AvatarRoundedFullAllBorders"
            JsxComponent={AvatarRoundedFullBaseBorderTextJsx.default}
            HtmlComponent={AvatarRoundedFullBaseBorderTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-700 p-8 sm:p-16">
              <AvatarRoundedFullAllBordersPreview
                AvatarRoundedFullBaseBorderText={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullBaseBorderTextJsx.default
                    : AvatarRoundedFullBaseBorderTextHTML.default
                }
                AvatarRoundedFullBaseBorderImg={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullBaseBorderImgJsx.default
                    : AvatarRoundedFullBaseBorderImgHTML.default
                }
                AvatarRoundedFullBaseBorderIcon={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullBaseBorderIconJsx.default
                    : AvatarRoundedFullBaseBorderIconHTML.default
                }
                AvatarRoundedFullLgBorderText={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullLgBorderTextJsx.default
                    : AvatarRoundedFullLgBorderTextHTML.default
                }
                AvatarRoundedFullLgBorderImg={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullLgBorderImgJsx.default
                    : AvatarRoundedFullLgBorderImgHTML.default
                }
                AvatarRoundedFullLgBorderIcon={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullLgBorderIconJsx.default
                    : AvatarRoundedFullLgBorderIconHTML.default
                }
                AvatarRoundedFullSmBorderText={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullSmBorderTextJsx.default
                    : AvatarRoundedFullSmBorderTextHTML.default
                }
                AvatarRoundedFullSmBorderImg={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullSmBorderImgJsx.default
                    : AvatarRoundedFullSmBorderImgHTML.default
                }
                AvatarRoundedFullSmBorderIcon={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullSmBorderIconJsx.default
                    : AvatarRoundedFullSmBorderIconHTML.default
                }
                AvatarRoundedFullXsBorderText={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullXsBorderTextJsx.default
                    : AvatarRoundedFullXsBorderTextHTML.default
                }
                AvatarRoundedFullXsBorderImg={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullXsBorderImgJsx.default
                    : AvatarRoundedFullXsBorderImgHTML.default
                }
                AvatarRoundedFullXsBorderIcon={
                  activeTabs[8].active_tab !== 1
                    ? AvatarRoundedFullXsBorderIconJsx.default
                    : AvatarRoundedFullXsBorderIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllBadgeTop">
            Full rounded avatars with top badge
          </h3>

          <PreviewBlock
            id="AvatarRoundedFullAllBadgeTop"
            JsxComponent={AvatarRoundedFullBaseBadgeTopTextJsx.default}
            HtmlComponent={AvatarRoundedFullBaseBadgeTopTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedFullAllBadgeTopPreview
                AvatarRoundedFullBaseBadgeTopText={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullBaseBadgeTopTextJsx.default
                    : AvatarRoundedFullBaseBadgeTopTextHTML.default
                }
                AvatarRoundedFullBaseBadgeTopImg={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullBaseBadgeTopImgJsx.default
                    : AvatarRoundedFullBaseBadgeTopImgHTML.default
                }
                AvatarRoundedFullBaseBadgeTopIcon={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullBaseBadgeTopIconJsx.default
                    : AvatarRoundedFullBaseBadgeTopIconHTML.default
                }
                AvatarRoundedFullLgBadgeTopText={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullLgBadgeTopTextJsx.default
                    : AvatarRoundedFullLgBadgeTopTextHTML.default
                }
                AvatarRoundedFullLgBadgeTopImg={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullLgBadgeTopImgJsx.default
                    : AvatarRoundedFullLgBadgeTopImgHTML.default
                }
                AvatarRoundedFullLgBadgeTopIcon={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullLgBadgeTopIconJsx.default
                    : AvatarRoundedFullLgBadgeTopIconHTML.default
                }
                AvatarRoundedFullSmBadgeTopText={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullSmBadgeTopTextJsx.default
                    : AvatarRoundedFullSmBadgeTopTextHTML.default
                }
                AvatarRoundedFullSmBadgeTopImg={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullSmBadgeTopImgJsx.default
                    : AvatarRoundedFullSmBadgeTopImgHTML.default
                }
                AvatarRoundedFullSmBadgeTopIcon={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullSmBadgeTopIconJsx.default
                    : AvatarRoundedFullSmBadgeTopIconHTML.default
                }
                AvatarRoundedFullXsBadgeTopText={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullXsBadgeTopTextJsx.default
                    : AvatarRoundedFullXsBadgeTopTextHTML.default
                }
                AvatarRoundedFullXsBadgeTopImg={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullXsBadgeTopImgJsx.default
                    : AvatarRoundedFullXsBadgeTopImgHTML.default
                }
                AvatarRoundedFullXsBadgeTopIcon={
                  activeTabs[9].active_tab !== 1
                    ? AvatarRoundedFullXsBadgeTopIconJsx.default
                    : AvatarRoundedFullXsBadgeTopIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllBadgeBottom">
            Full rounded avatars with bottom badge
          </h3>

          <PreviewBlock
            id="AvatarRoundedFullAllBadgeBottom"
            JsxComponent={AvatarRoundedFullBaseBadgeBottomTextJsx.default}
            HtmlComponent={AvatarRoundedFullBaseBadgeBottomTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedFullAllBadgeBottomPreview
                AvatarRoundedFullBaseBadgeBottomText={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullBaseBadgeBottomTextJsx.default
                    : AvatarRoundedFullBaseBadgeBottomTextHTML.default
                }
                AvatarRoundedFullBaseBadgeBottomImg={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullBaseBadgeBottomImgJsx.default
                    : AvatarRoundedFullBaseBadgeBottomImgHTML.default
                }
                AvatarRoundedFullBaseBadgeBottomIcon={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullBaseBadgeBottomIconJsx.default
                    : AvatarRoundedFullBaseBadgeBottomIconHTML.default
                }
                AvatarRoundedFullLgBadgeBottomText={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullLgBadgeBottomTextJsx.default
                    : AvatarRoundedFullLgBadgeBottomTextHTML.default
                }
                AvatarRoundedFullLgBadgeBottomImg={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullLgBadgeBottomImgJsx.default
                    : AvatarRoundedFullLgBadgeBottomImgHTML.default
                }
                AvatarRoundedFullLgBadgeBottomIcon={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullLgBadgeBottomIconJsx.default
                    : AvatarRoundedFullLgBadgeBottomIconHTML.default
                }
                AvatarRoundedFullSmBadgeBottomText={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullSmBadgeBottomTextJsx.default
                    : AvatarRoundedFullSmBadgeBottomTextHTML.default
                }
                AvatarRoundedFullSmBadgeBottomImg={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullSmBadgeBottomImgJsx.default
                    : AvatarRoundedFullSmBadgeBottomImgHTML.default
                }
                AvatarRoundedFullSmBadgeBottomIcon={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullSmBadgeBottomIconJsx.default
                    : AvatarRoundedFullSmBadgeBottomIconHTML.default
                }
                AvatarRoundedFullXsBadgeBottomText={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullXsBadgeBottomTextJsx.default
                    : AvatarRoundedFullXsBadgeBottomTextHTML.default
                }
                AvatarRoundedFullXsBadgeBottomImg={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullXsBadgeBottomImgJsx.default
                    : AvatarRoundedFullXsBadgeBottomImgHTML.default
                }
                AvatarRoundedFullXsBadgeBottomIcon={
                  activeTabs[10].active_tab !== 1
                    ? AvatarRoundedFullXsBadgeBottomIconJsx.default
                    : AvatarRoundedFullXsBadgeBottomIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllIndicatorBadgeTop">
            Full rounded avatars with top indicator
          </h3>

          <PreviewBlock
            id="AvatarRoundedFullAllIndicatorBadgeTop"
            JsxComponent={AvatarRoundedFullBaseIndicatorBadgeTopTextJsx.default}
            HtmlComponent={
              AvatarRoundedFullBaseIndicatorBadgeTopTextHTML.default
            }
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedFullAllIndicatorBadgeTopPreview
                AvatarRoundedFullBaseIndicatorBadgeTopText={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullBaseIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedFullBaseIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedFullBaseIndicatorBadgeTopImg={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullBaseIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedFullBaseIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedFullBaseIndicatorBadgeTopIcon={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullBaseIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedFullBaseIndicatorBadgeTopIconHTML.default
                }
                AvatarRoundedFullLgIndicatorBadgeTopText={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullLgIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedFullLgIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedFullLgIndicatorBadgeTopImg={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullLgIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedFullLgIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedFullLgIndicatorBadgeTopIcon={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullLgIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedFullLgIndicatorBadgeTopIconHTML.default
                }
                AvatarRoundedFullSmIndicatorBadgeTopText={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullSmIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedFullSmIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedFullSmIndicatorBadgeTopImg={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullSmIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedFullSmIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedFullSmIndicatorBadgeTopIcon={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullSmIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedFullSmIndicatorBadgeTopIconHTML.default
                }
                AvatarRoundedFullXsIndicatorBadgeTopText={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullXsIndicatorBadgeTopTextJsx.default
                    : AvatarRoundedFullXsIndicatorBadgeTopTextHTML.default
                }
                AvatarRoundedFullXsIndicatorBadgeTopImg={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullXsIndicatorBadgeTopImgJsx.default
                    : AvatarRoundedFullXsIndicatorBadgeTopImgHTML.default
                }
                AvatarRoundedFullXsIndicatorBadgeTopIcon={
                  activeTabs[11].active_tab !== 1
                    ? AvatarRoundedFullXsIndicatorBadgeTopIconJsx.default
                    : AvatarRoundedFullXsIndicatorBadgeTopIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllIndicatorBadgeBottom">
            Full rounded avatars with bottom indicator
          </h3>

          <PreviewBlock
            id="AvatarRoundedFullAllIndicatorBadgeBottom"
            JsxComponent={
              AvatarRoundedFullBaseIndicatorBadgeBottomTextJsx.default
            }
            HtmlComponent={
              AvatarRoundedFullBaseIndicatorBadgeBottomTextHTML.default
            }
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedFullAllIndicatorBadgeBottomPreview
                AvatarRoundedFullBaseIndicatorBadgeBottomText={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullBaseIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedFullBaseIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedFullBaseIndicatorBadgeBottomImg={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullBaseIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedFullBaseIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedFullBaseIndicatorBadgeBottomIcon={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullBaseIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedFullBaseIndicatorBadgeBottomIconHTML.default
                }
                AvatarRoundedFullLgIndicatorBadgeBottomText={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullLgIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedFullLgIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedFullLgIndicatorBadgeBottomImg={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullLgIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedFullLgIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedFullLgIndicatorBadgeBottomIcon={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullLgIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedFullLgIndicatorBadgeBottomIconHTML.default
                }
                AvatarRoundedFullSmIndicatorBadgeBottomText={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullSmIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedFullSmIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedFullSmIndicatorBadgeBottomImg={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullSmIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedFullSmIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedFullSmIndicatorBadgeBottomIcon={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullSmIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedFullSmIndicatorBadgeBottomIconHTML.default
                }
                AvatarRoundedFullXsIndicatorBadgeBottomText={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullXsIndicatorBadgeBottomTextJsx.default
                    : AvatarRoundedFullXsIndicatorBadgeBottomTextHTML.default
                }
                AvatarRoundedFullXsIndicatorBadgeBottomImg={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullXsIndicatorBadgeBottomImgJsx.default
                    : AvatarRoundedFullXsIndicatorBadgeBottomImgHTML.default
                }
                AvatarRoundedFullXsIndicatorBadgeBottomIcon={
                  activeTabs[12].active_tab !== 1
                    ? AvatarRoundedFullXsIndicatorBadgeBottomIconJsx.default
                    : AvatarRoundedFullXsIndicatorBadgeBottomIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllTooltip">
            Full rounded avatars with tooltip
          </h3>

          <PreviewBlock
            id="AvatarRoundedFullAllTooltip"
            JsxComponent={AvatarRoundedFullBaseTooltipTextJsx.default}
            HtmlComponent={AvatarRoundedFullBaseTooltipTextHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedFullAllTooltipPreview
                AvatarRoundedFullBaseTooltipText={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullBaseTooltipTextJsx.default
                    : AvatarRoundedFullBaseTooltipTextHTML.default
                }
                AvatarRoundedFullBaseTooltipImg={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullBaseTooltipImgJsx.default
                    : AvatarRoundedFullBaseTooltipImgHTML.default
                }
                AvatarRoundedFullBaseTooltipIcon={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullBaseTooltipIconJsx.default
                    : AvatarRoundedFullBaseTooltipIconHTML.default
                }
                AvatarRoundedFullLgTooltipText={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullLgTooltipTextJsx.default
                    : AvatarRoundedFullLgTooltipTextHTML.default
                }
                AvatarRoundedFullLgTooltipImg={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullLgTooltipImgJsx.default
                    : AvatarRoundedFullLgTooltipImgHTML.default
                }
                AvatarRoundedFullLgTooltipIcon={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullLgTooltipIconJsx.default
                    : AvatarRoundedFullLgTooltipIconHTML.default
                }
                AvatarRoundedFullSmTooltipText={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullSmTooltipTextJsx.default
                    : AvatarRoundedFullSmTooltipTextHTML.default
                }
                AvatarRoundedFullSmTooltipImg={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullSmTooltipImgJsx.default
                    : AvatarRoundedFullSmTooltipImgHTML.default
                }
                AvatarRoundedFullSmTooltipIcon={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullSmTooltipIconJsx.default
                    : AvatarRoundedFullSmTooltipIconHTML.default
                }
                AvatarRoundedFullXsTooltipText={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullXsTooltipTextJsx.default
                    : AvatarRoundedFullXsTooltipTextHTML.default
                }
                AvatarRoundedFullXsTooltipImg={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullXsTooltipImgJsx.default
                    : AvatarRoundedFullXsTooltipImgHTML.default
                }
                AvatarRoundedFullXsTooltipIcon={
                  activeTabs[13].active_tab !== 1
                    ? AvatarRoundedFullXsTooltipIconJsx.default
                    : AvatarRoundedFullXsTooltipIconHTML.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="AvatarRoundedFullAllGrouped">Grouped Avatars</h3>

          <PreviewBlock
            id="AvatarRoundedFullAllGrouped"
            JsxComponent={AvatarRoundedFullBaseGroupedBasicJsx.default}
            HtmlComponent={AvatarRoundedFullBaseGroupedBasicHTML.default}
            getActiveTabs={getActiveTabs}
            extraClassesResizableBox=""
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <AvatarRoundedFullAllGroupedPreview
                AvatarRoundedFullBaseGroupedBasic={
                  activeTabs[14].active_tab !== 1
                    ? AvatarRoundedFullBaseGroupedBasicJsx.default
                    : AvatarRoundedFullBaseGroupedBasicHTML.default
                }
                AvatarRoundedFullBaseGroupedCompact={
                  activeTabs[14].active_tab !== 1
                    ? AvatarRoundedFullBaseGroupedCompactJsx.default
                    : AvatarRoundedFullBaseGroupedCompactHTML.default
                }
                AvatarRoundedFullBaseGroupedTooltip={
                  activeTabs[14].active_tab !== 1
                    ? AvatarRoundedFullBaseGroupedTooltipJsx.default
                    : AvatarRoundedFullBaseGroupedTooltipHTML.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use the Avatar Component as a graphical representation of a person
            through a profile picture or image, an icon, or a string with
            initials.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          Avatars comes in 2 styles, base rounded - with 0.25rem (4px)
          border-radius, and full rounded corners. They also come in 4 sizes
          (xs, sm, base and lg) and they can contain an image, icon or initials
          to represent the user, along with{" "}
          <Link to="/components/badges">badge</Link> indicator and{" "}
          <Link to="/components/tooltips">toolitps</Link>:
          <ul>
            <li>
              <strong>Extra Small:</strong> 1.5rem (24px) width and height with
              1rem (16px) icon size (for the icon version and 0.75rem (12px)
              font size (for the version with the initials). The xs avatar size
              uses the{" "}
              <Link to="/components/badges#specs-heading">xs size badge</Link>
            </li>
            <li>
              <strong>Small:</strong> 2rem (32px) width and height with 1.25rem
              (20px) icon size (for the icon version) and 1rem (16px) font size
              (for the version with the initials). The small avatar size uses
              the{" "}
              <Link to="/components/badges#specs-heading">sm size badge</Link>
            </li>
            <li>
              <strong>Base:</strong> 2.5rem (40px) width and height with 1.5rem
              (24px) icon size (for the icon version) and 1.125rem (18px) font
              size (for the version with the initials). The small avatar size
              uses the{" "}
              <Link to="/components/badges#specs-heading">base size badge</Link>
            </li>
            <li>
              <strong>Large:</strong> 3rem (48px) width and height with 1.5rem
              (24px) icon size (for the icon version) and 1.125rem (18px) font
              size (for the version with the initials). The small avatar size
              uses the{" "}
              <Link to="/components/badges#specs-heading">base size badge</Link>
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>To make the avatars accessible make sure that:</p>
          <ul>
            <li>
              <strong>Image avatars:</strong> provide an alt/title attribute
              that corresponds to the user.
            </li>
            <li>
              <strong>Icon avatars:</strong> provide an aria-labelledby
              attribute along with a set of <code>&lt;title&gt;</code> and{" "}
              <code>&lt;desc&gt;</code> attributes for your SVG to describe the
              user.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
