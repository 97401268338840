import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedAllBadgeTopPreview(props) {
  const {
    AvatarRoundedLgBadgeTopText,
    AvatarRoundedLgBadgeTopImg,
    AvatarRoundedLgBadgeTopIcon,
    AvatarRoundedBaseBadgeTopText,
    AvatarRoundedBaseBadgeTopImg,
    AvatarRoundedBaseBadgeTopIcon,
    AvatarRoundedSmBadgeTopText,
    AvatarRoundedSmBadgeTopImg,
    AvatarRoundedSmBadgeTopIcon,
    AvatarRoundedXsBadgeTopText,
    AvatarRoundedXsBadgeTopImg,
    AvatarRoundedXsBadgeTopIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded top badge img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBadgeTopImg}
          componentName="AvatarRoundedLgBadgeTopImg"
        >
          {/*<!-- Component: Rounded lg sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=10"
              alt="user name"
              title="user name"
              width=" 48"
              height=" 48"
              className="max-w-full rounded"
            />
            <span className="absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized img avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBadgeTopImg}
          componentName="AvatarRoundedBaseBadgeTopImg"
        >
          {/*<!-- Component: Rounded base sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=11"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded "
            />
            <span className=" absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized img avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBadgeTopImg}
          componentName="AvatarRoundedSmBadgeTopImg"
        >
          {/*<!-- Component: Rounded sm sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=12"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded "
            />
            <span className=" absolute -top-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized img avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBadgeTopImg}
          componentName="AvatarRoundedXsBadgeTopImg"
        >
          {/*<!-- Component: Rounded xs sized img avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=13"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded "
            />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized img avatars with top right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded base sized top badge img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded top badge icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBadgeTopIcon}
          componentName="AvatarRoundedLgBadgeTopIcon"
        >
          {/*<!-- Component: Rounded lg sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-21 desc-21"
              role="graphics-symbol"
            >
              <title id="title-21">User Icon</title>
              <desc id="desc-21">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized icon avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBadgeTopIcon}
          componentName="AvatarRoundedBaseBadgeTopIcon"
        >
          {/*<!-- Component: Rounded bse sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-22 desc-22"
              role="graphics-symbol"
            >
              <title id="title-22">User Icon</title>
              <desc id="desc-22">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded bse sized icon avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBadgeTopIcon}
          componentName="AvatarRoundedSmBadgeTopIcon"
        >
          {/*<!-- Component: Rounded sm sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-23 desc-23"
              role="graphics-symbol"
            >
              <title id="title-23">User Icon</title>
              <desc id="desc-23">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized icon avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBadgeTopIcon}
          componentName="AvatarRoundedXsBadgeTopIcon"
        >
          {/*<!-- Component: Rounded xs sized icon avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-24 desc-24"
              role="graphics-symbol"
            >
              <title id="title-24">User Icon</title>
              <desc id="desc-24">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized icon avatars with top right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded base sized top badge icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded top badge text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBadgeTopText}
          componentName="AvatarRoundedLgBadgeTopText"
        >
          {/*<!-- Component: Rounded lg sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized text avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBadgeTopText}
          componentName="AvatarRoundedBaseBadgeTopText"
        >
          {/*<!-- Component: Rounded base sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized text avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBadgeTopText}
          componentName="AvatarRoundedSmBadgeTopText"
        >
          {/*<!-- Component: Rounded sm sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -top-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized text avatars with top right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBadgeTopText}
          componentName="AvatarRoundedXsBadgeTopText"
        >
          {/*<!-- Component: Rounded xs sized text avatars with top right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized text avatars with top right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded base sized top badge text avatars --> */}
      </div>
    </div>
  )
}
