import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllBordersPreview(props) {
  const {
    AvatarRoundedFullLgBorderText,
    AvatarRoundedFullLgBorderImg,
    AvatarRoundedFullLgBorderIcon,
    AvatarRoundedFullBaseBorderText,
    AvatarRoundedFullBaseBorderImg,
    AvatarRoundedFullBaseBorderIcon,
    AvatarRoundedFullSmBorderText,
    AvatarRoundedFullSmBorderImg,
    AvatarRoundedFullSmBorderIcon,
    AvatarRoundedFullXsBorderText,
    AvatarRoundedFullXsBorderImg,
    AvatarRoundedFullXsBorderIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full img avatars with borders --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBorderImg}
          componentName="AvatarRoundedFullLgBorderImg"
        >
          {/*<!-- Component: Rounded full lg sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=30"
              alt="user name"
              title="user name"
              width="48"
              height="48"
              className="max-w-full rounded-full border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded full lg sized img avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBorderImg}
          componentName="AvatarRoundedFullBaseBorderImg"
        >
          {/*<!-- Component: Rounded full base sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=31"
              alt="user name"
              title="user name"
              width="40"
              height="40"
              className="max-w-full rounded-full border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded full base sized img avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBorderImg}
          componentName="AvatarRoundedFullSmBorderImg"
        >
          {/*<!-- Component: Rounded full sm sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=32"
              alt="user name"
              title="user name"
              width="32"
              height="32"
              className="max-w-full rounded-full border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded full sm sized img avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBorderImg}
          componentName="AvatarRoundedFullXsBorderImg"
        >
          {/*<!-- Component: Rounded full xs sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=33"
              alt="user name"
              title="user name"
              width="24"
              height="24"
              className="max-w-full rounded-full border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded full xs sized img avatar with border --> */}
        </CopyComponent>
        {/*<!-- End All rounded full img avatars with borders --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full icon avatars with borders --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBorderIcon}
          componentName="AvatarRoundedFullLgBorderIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-65 description-65"
              role="graphics-symbol"
            >
              <title id="title-65">User Icon</title>
              <desc id="description-65">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full lg sized icon avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBorderIcon}
          componentName="AvatarRoundedFullBaseBorderIcon"
        >
          {/*<!-- Component: Rounded full base sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-66 description-66"
              role="graphics-symbol"
            >
              <title id="title-66">User Icon</title>
              <desc id="description-66">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full base sized icon avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBorderIcon}
          componentName="AvatarRoundedFullSmBorderIcon"
        >
          {/*<!-- Component: Rounded full sm sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-67 description-67"
              role="graphics-symbol"
            >
              <title id="title-67">User Icon</title>
              <desc id="description-67">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full sm sized icon avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBorderIcon}
          componentName="AvatarRoundedFullXsBorderIcon"
        >
          {/*<!-- Component: Rounded full xs sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-68 description-68"
              role="graphics-symbol"
            >
              <title id="title-68">User Icon</title>
              <desc id="description-68">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded full xs sized icon avatar with border --> */}
        </CopyComponent>
        {/*<!-- End All rounded full icon avatars with borders --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full text avatars with borders --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBorderText}
          componentName="AvatarRoundedFullLgBorderText"
        >
          {/*<!-- Component: Rounded full lg sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full lg sized text avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBorderText}
          componentName="AvatarRoundedFullBaseBorderText"
        >
          {/*<!-- Component: Rounded full base sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full base sized text avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBorderText}
          componentName="AvatarRoundedFullSmBorderText"
        >
          {/*<!-- Component: Rounded full sm sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full sm sized text avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBorderText}
          componentName="AvatarRoundedFullXsBorderText"
        >
          {/*<!-- Component: Rounded full xs sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-xs text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded full xs sized text avatar with border --> */}
        </CopyComponent>
        {/*<!-- End All rounded full text avatars with borders --> */}
      </div>
    </div>
  )
}
