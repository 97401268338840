import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllBadgeBottomPreview(props) {
  const {
    AvatarRoundedFullLgBadgeBottomText,
    AvatarRoundedFullLgBadgeBottomImg,
    AvatarRoundedFullLgBadgeBottomIcon,
    AvatarRoundedFullBaseBadgeBottomText,
    AvatarRoundedFullBaseBadgeBottomImg,
    AvatarRoundedFullBaseBadgeBottomIcon,
    AvatarRoundedFullSmBadgeBottomText,
    AvatarRoundedFullSmBadgeBottomImg,
    AvatarRoundedFullSmBadgeBottomIcon,
    AvatarRoundedFullXsBadgeBottomText,
    AvatarRoundedFullXsBadgeBottomImg,
    AvatarRoundedFullXsBadgeBottomIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full badge bottom img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBadgeBottomImg}
          componentName="AvatarRoundedFullLgBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full lg sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=22"
              alt="user name"
              title="user name"
              width=" 48"
              height=" 48"
              className="max-w-full rounded-full"
            />
            <span className="absolute -bottom-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized img avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBadgeBottomImg}
          componentName="AvatarRoundedFullBaseBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full base sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=23"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded-full "
            />
            <span className=" absolute -bottom-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized img avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBadgeBottomImg}
          componentName="AvatarRoundedFullSmBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full sm sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=24"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded-full "
            />
            <span className=" absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized img avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBadgeBottomImg}
          componentName="AvatarRoundedFullXsBadgeBottomImg"
        >
          {/*<!-- Component: Rounded full xs sized img avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=25"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded-full "
            />
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized img avatars with bottom right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full base sized badge bottom img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full badge bottom icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBadgeBottomIcon}
          componentName="AvatarRoundedFullLgBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-15 desc-15"
              role="graphics-symbol"
            >
              <title id="title-15">User Icon</title>
              <desc id="desc-15">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized icon avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBadgeBottomIcon}
          componentName="AvatarRoundedFullBaseBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-16 desc-16"
              role="graphics-symbol"
            >
              <title id="title-16">User Icon</title>
              <desc id="desc-16">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized icon avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBadgeBottomIcon}
          componentName="AvatarRoundedFullSmBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-17 desc-17"
              role="graphics-symbol"
            >
              <title id="title-17">User Icon</title>
              <desc id="desc-17">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized icon avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBadgeBottomIcon}
          componentName="AvatarRoundedFullXsBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-18 desc-18"
              role="graphics-symbol"
            >
              <title id="title-18">User Icon</title>
              <desc id="desc-18">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized icon avatars with bottom right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full base sized badge bottom icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full badge bottom text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgBadgeBottomText}
          componentName="AvatarRoundedFullLgBadgeBottomText"
        >
          {/*<!-- Component: Rounded full lg sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -bottom-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized text avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseBadgeBottomText}
          componentName="AvatarRoundedFullBaseBadgeBottomText"
        >
          {/*<!-- Component: Rounded full base sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -bottom-1.5 -right-1.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized text avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmBadgeBottomText}
          componentName="AvatarRoundedFullSmBadgeBottomText"
        >
          {/*<!-- Component: Rounded full sm sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -bottom-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
              7 <span className="sr-only"> new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized text avatars with bottom right badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsBadgeBottomText}
          componentName="AvatarRoundedFullXsBadgeBottomText"
        >
          {/*<!-- Component: Rounded full xs sized text avatars with bottom right badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized text avatars with bottom right badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full base sized badge bottom text avatars --> */}
      </div>
    </div>
  )
}
