import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedAllIndicatorBadgeBottomPreview(props) {
  const {
    AvatarRoundedLgIndicatorBadgeBottomText,
    AvatarRoundedLgIndicatorBadgeBottomImg,
    AvatarRoundedLgIndicatorBadgeBottomIcon,
    AvatarRoundedBaseIndicatorBadgeBottomText,
    AvatarRoundedBaseIndicatorBadgeBottomImg,
    AvatarRoundedBaseIndicatorBadgeBottomIcon,
    AvatarRoundedSmIndicatorBadgeBottomText,
    AvatarRoundedSmIndicatorBadgeBottomImg,
    AvatarRoundedSmIndicatorBadgeBottomIcon,
    AvatarRoundedXsIndicatorBadgeBottomText,
    AvatarRoundedXsIndicatorBadgeBottomImg,
    AvatarRoundedXsIndicatorBadgeBottomIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded bottom indicator badge img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgIndicatorBadgeBottomImg}
          componentName="AvatarRoundedLgIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded lg sized img avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=34"
              alt="user name"
              title="user name"
              width=" 48"
              height=" 48"
              className="max-w-full rounded"
            />
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseIndicatorBadgeBottomImg}
          componentName="AvatarRoundedBaseIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded base sized img avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=35"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded "
            />
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmIndicatorBadgeBottomImg}
          componentName="AvatarRoundedSmIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded sm sized img avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=36"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded "
            />
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsIndicatorBadgeBottomImg}
          componentName="AvatarRoundedXsIndicatorBadgeBottomImg"
        >
          {/*<!-- Component: Rounded xs sized img avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=37"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded "
            />
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized img avatars with bottom right indicator badge --> */}
        </CopyComponent>
        {/*<!-- Start All rounded bottom indicator badge img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded bottom indicator badge icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedLgIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded lg sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-31 desc-31"
              role="graphics-symbol"
            >
              <title id="title-31">User Icon</title>
              <desc id="desc-31">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedBaseIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded base sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-32 desc-32"
              role="graphics-symbol"
            >
              <title id="title-32">User Icon</title>
              <desc id="desc-32">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedSmIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded sm sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-33 desc-33"
              role="graphics-symbol"
            >
              <title id="title-33">User Icon</title>
              <desc id="desc-33">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsIndicatorBadgeBottomIcon}
          componentName="AvatarRoundedXsIndicatorBadgeBottomIcon"
        >
          {/*<!-- Component: Rounded xs sized icon avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-34 desc-34"
              role="graphics-symbol"
            >
              <title id="title-34">User Icon</title>
              <desc id="desc-34">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized icon avatars with bottom right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded bottom indicator badge icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded bottom indicator badge text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgIndicatorBadgeBottomText}
          componentName="AvatarRoundedLgIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded lg sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded lg sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseIndicatorBadgeBottomText}
          componentName="AvatarRoundedBaseIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded base sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded base sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmIndicatorBadgeBottomText}
          componentName="AvatarRoundedSmIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded sm sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded sm sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsIndicatorBadgeBottomText}
          componentName="AvatarRoundedXsIndicatorBadgeBottomText"
        >
          {/*<!-- Component: Rounded xs sized text avatars with bottom right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -bottom-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded xs sized text avatars with bottom right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded bottom indicator badge text avatars --> */}
      </div>
    </div>
  )
}
