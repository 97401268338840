import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllIndicatorBadgeTopPreview(props) {
  const {
    AvatarRoundedFullLgIndicatorBadgeTopText,
    AvatarRoundedFullLgIndicatorBadgeTopImg,
    AvatarRoundedFullLgIndicatorBadgeTopIcon,
    AvatarRoundedFullBaseIndicatorBadgeTopText,
    AvatarRoundedFullBaseIndicatorBadgeTopImg,
    AvatarRoundedFullBaseIndicatorBadgeTopIcon,
    AvatarRoundedFullSmIndicatorBadgeTopText,
    AvatarRoundedFullSmIndicatorBadgeTopImg,
    AvatarRoundedFullSmIndicatorBadgeTopIcon,
    AvatarRoundedFullXsIndicatorBadgeTopText,
    AvatarRoundedFullXsIndicatorBadgeTopImg,
    AvatarRoundedFullXsIndicatorBadgeTopIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full top indicator badge img avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgIndicatorBadgeTopImg}
          componentName="AvatarRoundedFullLgIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded full lg sized img avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=38"
              alt="user name"
              title="user name"
              width=" 48"
              height=" 48"
              className="max-w-full rounded-full"
            />
            <span className="absolute top-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized img avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseIndicatorBadgeTopImg}
          componentName="AvatarRoundedFullBaseIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded full base sized img avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=39"
              alt="user name"
              title="user name"
              width=" 40"
              height="40"
              className="max-w-full rounded-full "
            />
            <span className="absolute top-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white ">
              <span className="sr-only "> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized img avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmIndicatorBadgeTopImg}
          componentName="AvatarRoundedFullSmIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded full sm sized img avatars with top right indicator badge --> */}
          <a
            href=" #"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=40"
              alt="user name"
              title="user name"
              width=" 32"
              height="32"
              className="max-w-full rounded-full "
            />
            <span className=" absolute -top-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized img avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsIndicatorBadgeTopImg}
          componentName="AvatarRoundedFullXsIndicatorBadgeTopImg"
        >
          {/*<!-- Component: Rounded full xs sized img avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=41"
              alt="user name"
              title="user name"
              width=" 24"
              height="24"
              className="max-w-full rounded-full "
            />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized img avatars with top right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full top indicator badge img avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full top indicator badge icon avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgIndicatorBadgeTopIcon}
          componentName="AvatarRoundedFullLgIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-45 desc-45"
              role="graphics-symbol"
            >
              <title id="title-45">User Icon</title>
              <desc id="desc-45">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute top-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized icon avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseIndicatorBadgeTopIcon}
          componentName="AvatarRoundedFullBaseIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full base sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-46 desc-46"
              role="graphics-symbol"
            >
              <title id="title-46">User Icon</title>
              <desc id="desc-46">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute top-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized icon avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmIndicatorBadgeTopIcon}
          componentName="AvatarRoundedFullSmIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full sm sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-47 desc-47"
              role="graphics-symbol"
            >
              <title id="title-47">User Icon</title>
              <desc id="desc-47">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized icon avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsIndicatorBadgeTopIcon}
          componentName="AvatarRoundedFullXsIndicatorBadgeTopIcon"
        >
          {/*<!-- Component: Rounded full xs sized icon avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-48 desc-48"
              role="graphics-symbol"
            >
              <title id="title-48">User Icon</title>
              <desc id="desc-48">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized icon avatars with top right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full top indicator badge icon avatars --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full top indicator badge text avatars --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgIndicatorBadgeTopText}
          componentName="AvatarRoundedFullLgIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded full lg sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute top-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized text avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseIndicatorBadgeTopText}
          componentName="AvatarRoundedFullBaseIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded full base sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
          >
            CP
            <span className="absolute top-0 right-0 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized text avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmIndicatorBadgeTopText}
          componentName="AvatarRoundedFullSmIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded full sm sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white"
          >
            CP
            <span className="absolute -top-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized text avatars with top right indicator badge --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsIndicatorBadgeTopText}
          componentName="AvatarRoundedFullXsIndicatorBadgeTopText"
        >
          {/*<!-- Component: Rounded full xs sized text avatars with top right indicator badge --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white"
          >
            CP
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> 7 new emails </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized text avatars with top right indicator badge --> */}
        </CopyComponent>
        {/*<!-- End All rounded full top indicator badge text avatars --> */}
      </div>
    </div>
  )
}
