import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllGroupedPreview(props) {
  const {
    AvatarRoundedFullBaseGroupedBasic,
    AvatarRoundedFullBaseGroupedCompact,
    AvatarRoundedFullBaseGroupedTooltip,
  } = props
  return (
    <div className="flex flex-col items-center gap-10 sm:flex-row">
      <div className="flex flex-col items-center">
        <h4 className="text-sm font-medium">Basic</h4>
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseGroupedBasic}
          componentName="AvatarRoundedFullBaseGroupedBasic"
        >
          {/*<!-- Component: Rounded full basic grouped avatars --> */}
          <div className="flex w-full items-center justify-center p-10">
            <div className="flex -space-x-2">
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
              >
                <img
                  src="https://i.pravatar.cc/40?img=31"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-white"
              >
                JL
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
              >
                <img
                  src="https://i.pravatar.cc/40?img=33"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-01 desc-01"
                  role="graphics-symbol"
                >
                  <title id="title-01">User Icon</title>
                  <desc id="desc-01">
                    User icon associated with a particular user account
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
              >
                <img
                  src="https://i.pravatar.cc/40?img=34"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-slate-200 text-sm text-slate-500"
              >
                +4
              </a>
            </div>
          </div>
          {/*<!-- End Rounded full basic grouped avatars --> */}
        </CopyComponent>
      </div>

      <div className="flex flex-col items-center">
        <h4 className="text-sm font-medium">Compact</h4>
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseGroupedCompact}
          componentName="AvatarRoundedFullBaseGroupedCompact"
        >
          {/*<!-- Component: Rounded full compact grouped avatars --> */}
          <div className="flex w-full items-center justify-center p-10">
            <div className="flex -space-x-4 transition-all hover:-space-x-2">
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white transition-all"
              >
                <img
                  src="https://i.pravatar.cc/40?img=31"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-white transition-all"
              >
                JL
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white transition-all"
              >
                <img
                  src="https://i.pravatar.cc/40?img=33"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white transition-all"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-02 desc-02"
                  role="graphics-symbol"
                >
                  <title id="title-02">User Icon</title>
                  <desc id="desc-02">
                    User icon associated with a particular user account
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white transition-all"
              >
                <img
                  src="https://i.pravatar.cc/40?img=34"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-slate-200 text-sm text-slate-500 transition-all"
              >
                +4
              </a>
            </div>
          </div>
          {/*<!-- End Rounded full compact grouped avatars --> */}
        </CopyComponent>
      </div>

      <div className="flex flex-col items-center">
        <h4 className="text-sm font-medium">With tooltip</h4>
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseGroupedTooltip}
          componentName="AvatarRoundedFullBaseGroupedTooltip"
        >
          {/*<!-- Component: Rounded full grouped avatars with tooltip --> */}
          <div className="flex w-full items-center justify-center p-10">
            <div className="flex -space-x-2">
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
              >
                <img
                  src="https://i.pravatar.cc/40?img=31"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-emerald-500 text-white"
              >
                {" "}
                JL{" "}
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white"
              >
                <img
                  src="https://i.pravatar.cc/40?img=33"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>
              <a
                href="javascript:void(0)"
                className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-03 desc-03"
                  role="graphics-symbol"
                >
                  <title id="title-03">User Icon</title>
                  <desc id="desc-03">
                    User icon associated with a particular user account
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </a>
              <a
                href="javascript:void(0)"
                className="i relative inline-flex rounded-full text-white"
              >
                <img
                  src="https://i.pravatar.cc/40?img=34"
                  alt="user name"
                  title="user name"
                  width="40"
                  height="40"
                  className="max-w-full rounded-full border-2 border-white"
                />
              </a>

              <a
                href="javascript:void(0)"
                className="group relative inline-flex h-10 w-10 cursor-pointer items-center justify-center overflow-hidden rounded-full border-2 border-white bg-slate-200 text-sm text-slate-500 before:invisible before:absolute before:bottom-[90%] before:left-1/2 before:z-10 before:mb-2 before:min-w-max before:-translate-x-1/2 before:rounded before:bg-slate-800/90 before:p-2 before:text-center before:text-xs before:text-white before:opacity-0 before:transition-all before:content-[attr(data-text)] hover:overflow-visible hover:before:visible hover:before:bottom-full hover:before:block hover:before:opacity-100"
                aria-describedby="tooltip-05"
              >
                +2
                <span
                  role="tooltip"
                  id="tooltip-05"
                  className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-36 -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
                >
                  {" "}
                  John Langan, Christos{" "}
                </span>
              </a>
            </div>
          </div>
          {/*<!-- End Rounded full grouped avatars with tooltip --> */}
        </CopyComponent>
      </div>
    </div>
  )
}
