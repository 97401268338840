import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedFullAllTooltipPreview(props) {
  const {
    AvatarRoundedFullLgTooltipText,
    AvatarRoundedFullLgTooltipImg,
    AvatarRoundedFullLgTooltipIcon,
    AvatarRoundedFullBaseTooltipText,
    AvatarRoundedFullBaseTooltipImg,
    AvatarRoundedFullBaseTooltipIcon,
    AvatarRoundedFullSmTooltipText,
    AvatarRoundedFullSmTooltipImg,
    AvatarRoundedFullSmTooltipIcon,
    AvatarRoundedFullXsTooltipText,
    AvatarRoundedFullXsTooltipImg,
    AvatarRoundedFullXsTooltipIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full img avatars with tooltip --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgTooltipImg}
          componentName="AvatarRoundedFullLgTooltipImg"
        >
          {/*<!-- Component: Rounded full lg sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded-full text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/48?img=6"
                alt="user name"
                title="user name"
                width="48"
                height="48"
                className="max-w-full rounded-full"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded full lg sized img avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseTooltipImg}
          componentName="AvatarRoundedFullBaseTooltipImg"
        >
          {/*<!-- Component: Rounded full base sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded-full text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/40?img=7"
                alt="user name"
                title="user name"
                width="40"
                height="40"
                className="max-w-full rounded-full"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded full base sized img avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmTooltipImg}
          componentName="AvatarRoundedFullSmTooltipImg"
        >
          {/*<!-- Component: Rounded full sm sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/32?img=8"
                alt="user name"
                title="user name"
                width="32"
                height="32"
                className="max-w-full rounded-full"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded full sm sized img avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsTooltipImg}
          componentName="AvatarRoundedFullXsTooltipImg"
        >
          {/*<!-- Component: Rounded full xs sized img avatars with tooltip --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded-full text-white "
          >
            <span
              className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
              aria-describedby="tooltip-05"
            >
              {/*    <!-- Start Tooltip trigger --> */}
              <img
                src="https://i.pravatar.cc/24?img=9"
                alt="user name"
                title="user name"
                width="24"
                height="24"
                className="max-w-full rounded-full"
              />
              {/*    <!-- End Tooltip trigger --> */}
              <span
                role="tooltip"
                id="tooltip-05"
                className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
              >
                User's Name
              </span>
            </span>
          </a>
          {/*<!-- End Rounded full xs sized img avatars with tooltip --> */}
        </CopyComponent>
        {/*<!-- End All rounded full img avatars with tooltip --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full icon avatars with tooltip --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgTooltipIcon}
          componentName="AvatarRoundedFullLgTooltipIcon"
        >
          {/*<!-- Component: Rounded full lg sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-55 desc-55"
                role="graphics-symbol"
              >
                <title id="title-55">User Icon</title>
                <desc id="desc-55">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full lg sized icon avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseTooltipIcon}
          componentName="AvatarRoundedFullBaseTooltipIcon"
        >
          {/*<!-- Component: Rounded full base sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-56 desc-56"
                role="graphics-symbol"
              >
                <title id="title-56">User Icon</title>
                <desc id="desc-56">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full base sized icon avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmTooltipIcon}
          componentName="AvatarRoundedFullSmTooltipIcon"
        >
          {/*<!-- Component: Rounded full sm sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-57 desc-57"
                role="graphics-symbol"
              >
                <title id="title-57">User Icon</title>
                <desc id="desc-57">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full sm sized icon avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsTooltipIcon}
          componentName="AvatarRoundedFullXsTooltipIcon"
        >
          {/*<!-- Component: Rounded full xs sized icon avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1.5"
                aria-labelledby="title-58 desc-58"
                role="graphics-symbol"
              >
                <title id="title-58">User Icon</title>
                <desc id="desc-58">
                  User icon associated with a particular user account
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full xs sized icon avatars with tooltip --> */}
        </CopyComponent>
        {/*<!-- End All rounded full icon avatars with tooltip --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded full text avatars with tooltip --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullLgTooltipText}
          componentName="AvatarRoundedFullLgTooltipText"
        >
          {/*<!-- Component: Rounded full lg sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-12 w-12 items-center justify-center rounded-full bg-emerald-500 text-lg text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full lg sized text avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullBaseTooltipText}
          componentName="AvatarRoundedFullBaseTooltipText"
        >
          {/*<!-- Component: Rounded full base sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-10 w-10 items-center justify-center rounded-full bg-emerald-500 text-lg text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full base sized text avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullSmTooltipText}
          componentName="AvatarRoundedFullSmTooltipText"
        >
          {/*<!-- Component: Rounded full sm sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-emerald-500 text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full sm sized text avatars with tooltip --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedFullXsTooltipText}
          componentName="AvatarRoundedFullXsTooltipText"
        >
          {/*<!-- Component: Rounded full xs sized text avatars with tooltip --> */}
          <span
            className="group relative cursor-pointer overflow-hidden hover:overflow-visible"
            aria-describedby="tooltip-05"
          >
            {/*  <!-- Start Tooltip trigger --> */}
            <a
              href="javascript:void(0)"
              className="relative inline-flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 text-xs text-white "
            >
              CP
            </a>
            {/*  <!-- End Tooltip trigger --> */}
            <span
              role="tooltip"
              id="tooltip-05"
              className="invisible absolute bottom-full left-1/2 z-10 mb-2 w-[89px] -translate-x-1/2 rounded bg-slate-700 p-2 text-xs text-white opacity-0 transition-all before:invisible before:absolute before:left-1/2 before:top-full before:z-10 before:mb-2 before:-ml-1 before:border-x-4 before:border-t-4 before:border-x-transparent before:border-t-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
            >
              User's Name
            </span>
          </span>
          {/*<!-- End Rounded full xs sized text avatars with tooltip --> */}
        </CopyComponent>
        {/*<!-- End All rounded full text avatars with tooltip --> */}
      </div>
    </div>
  )
}
