import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function AvatarRoundedAllBordersPreview(props) {
  const {
    AvatarRoundedLgBorderText,
    AvatarRoundedLgBorderImg,
    AvatarRoundedLgBorderIcon,
    AvatarRoundedBaseBorderText,
    AvatarRoundedBaseBorderImg,
    AvatarRoundedBaseBorderIcon,
    AvatarRoundedSmBorderText,
    AvatarRoundedSmBorderImg,
    AvatarRoundedSmBorderIcon,
    AvatarRoundedXsBorderText,
    AvatarRoundedXsBorderImg,
    AvatarRoundedXsBorderIcon,
  } = props

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded img avatars with borders --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBorderImg}
          componentName="AvatarRoundedLgBorderImg"
        >
          {/*<!-- Component: Rounded lg sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/48?img=1"
              alt="user name"
              title="user name"
              width="48"
              height="48"
              className="max-w-full rounded border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded lg sized img avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBorderImg}
          componentName="AvatarRoundedBaseBorderImg"
        >
          {/*<!-- Component: Rounded base sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/40?img=3"
              alt="user name"
              title="user name"
              width="40"
              height="40"
              className="max-w-full rounded border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded base sized img avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBorderImg}
          componentName="AvatarRoundedSmBorderImg"
        >
          {/*<!-- Component: Rounded sm sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/32?img=4"
              alt="user name"
              title="user name"
              width="32"
              height="32"
              className="max-w-full rounded border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded sm sized img avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBorderImg}
          componentName="AvatarRoundedXsBorderImg"
        >
          {/*<!-- Component: Rounded xs sized img avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded text-white"
          >
            <img
              src="https://i.pravatar.cc/24?img=5"
              alt="user name"
              title="user name"
              width="24"
              height="24"
              className="max-w-full rounded border-2 border-white"
            />
          </a>
          {/*<!-- End Rounded xs sized img avatar with border --> */}
        </CopyComponent>
        {/*<!-- End All rounded img avatars with borders --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded icon avatars with borders --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBorderIcon}
          componentName="AvatarRoundedLgBorderIcon"
        >
          {/*<!-- Component: Rounded lg sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded border-2 border-white bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-61 desc-61"
              role="graphics-symbol"
            >
              <title id="title-61">User Icon</title>
              <desc id="desc-61">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded lg sized icon avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBorderIcon}
          componentName="AvatarRoundedBaseBorderIcon"
        >
          {/*<!-- Component: Rounded base sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded border-2 border-white bg-emerald-500 text-lg text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-62 desc-62"
              role="graphics-symbol"
            >
              <title id="title-62">User Icon</title>
              <desc id="desc-62">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded base sized icon avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBorderIcon}
          componentName="AvatarRoundedSmBorderIcon"
        >
          {/*<!-- Component: Rounded sm sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded border-2 border-white bg-emerald-500 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-63 desc-63"
              role="graphics-symbol"
            >
              <title id="title-63">User Icon</title>
              <desc id="desc-63">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded sm sized icon avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBorderIcon}
          componentName="AvatarRoundedXsBorderIcon"
        >
          {/*<!-- Component: Rounded xs sized icon avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded border-2 border-white bg-emerald-500 text-xs text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-64 desc-64"
              role="graphics-symbol"
            >
              <title id="title-64">User Icon</title>
              <desc id="desc-64">
                User icon associated with a particular user account
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
          </a>
          {/*<!-- End Rounded xs sized icon avatar with border --> */}
        </CopyComponent>
        {/*<!-- End All rounded icon avatars with borders --> */}
      </div>

      <div className="flex flex-col items-center gap-10 sm:flex-row">
        {/*<!-- Start All rounded text avatars with borders --> */}
        <CopyComponent
          copyToClipboardCode={AvatarRoundedLgBorderText}
          componentName="AvatarRoundedLgBorderText"
        >
          {/*<!-- Component: Rounded lg sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-12 w-12 items-center justify-center rounded border-2 border-white bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded lg sized text avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedBaseBorderText}
          componentName="AvatarRoundedBaseBorderText"
        >
          {/*<!-- Component: Rounded base sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-10 w-10 items-center justify-center rounded border-2 border-white bg-emerald-500 text-lg text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded base sized text avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedSmBorderText}
          componentName="AvatarRoundedSmBorderText"
        >
          {/*<!-- Component: Rounded sm sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-8 w-8 items-center justify-center rounded border-2 border-white bg-emerald-500 text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded sm sized text avatar with border --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={AvatarRoundedXsBorderText}
          componentName="AvatarRoundedXsBorderText"
        >
          {/*<!-- Component: Rounded xs sized text avatar with border --> */}
          <a
            href="javascript:void(0)"
            className="relative inline-flex h-6 w-6 items-center justify-center rounded border-2 border-white bg-emerald-500 text-xs text-white"
          >
            CP
          </a>
          {/*<!-- End Rounded xs sized text avatar with border --> */}
        </CopyComponent>
        {/*<!-- End All rounded text avatars with borders --> */}
      </div>
    </div>
  )
}
