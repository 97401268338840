import React from "react"

export default function AvatarHeroComponent() {
  return (
    <div className="flex flex-col items-center gap-10 rounded-md sm:flex-row">
      {/*<!-- Component: Rounded lg sized image avatar with top right badge --> */}
      <a
        href="#"
        className="relative inline-flex items-center justify-center w-12 h-12 text-white rounded"
      >
        <img
          src="https://i.pravatar.cc/48?img=10"
          alt="user name"
          title="user name"
          width="48"
          height="48"
          className="max-w-full rounded"
        />
        <span className="absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
          7<span className="sr-only"> new emails </span>
        </span>
      </a>
      {/*<!-- End Rounded lg sized image avatar with top right badge --> */}

      {/*<!-- Component: Rounded base sized image avatar with top right badge --> */}
      <a
        href="#"
        className="relative inline-flex items-center justify-center w-10 h-10 text-white rounded"
      >
        <img
          src="https://i.pravatar.cc/40?img=11"
          alt="user name"
          title="user name"
          width="40"
          height="40"
          className="max-w-full rounded"
        />
        <span className="absolute -top-2.5 -right-2.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-sm text-white">
          7<span className="sr-only"> new emails </span>
        </span>
      </a>
      {/*<!-- End Rounded base sized image avatar with top right badge --> */}

      {/*<!-- Component: Rounded sm sized image avatar with top right badge --> */}
      <a
        href="#"
        className="relative inline-flex items-center justify-center w-8 h-8 text-white rounded"
      >
        <img
          src="https://i.pravatar.cc/32?img=12"
          alt="user name"
          title="user name"
          width="32"
          height="32"
          className="max-w-full rounded"
        />
        <span className="absolute -top-2 -right-2 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 px-1.5 text-xs text-white">
          7<span className="sr-only"> new emails </span>
        </span>
      </a>
      {/*<!-- End Rounded sm sized image avatar with top right badge --> */}

      {/*<!-- Component: Rounded xs sized image avatar with top right badge --> */}
      <a
        href="#"
        className="relative inline-flex items-center justify-center w-6 h-6 text-white rounded"
      >
        <img
          src="https://i.pravatar.cc/24?img=13"
          alt="user name"
          title="user name"
          width="24"
          height="24"
          className="max-w-full rounded"
        />
        <span className="absolute inline-flex items-center justify-center gap-1 p-1 text-sm text-white bg-pink-500 border-2 border-white rounded-full -top-1 -right-1">
          <span className="sr-only"> 7 new emails </span>
        </span>
      </a>
      {/*<!-- End Rounded xs sized image avatar with top right badge --> */}
    </div>
  )
}
